import {BACKEND_API} from "../axios-config";

export default {
    state: {
        expandBlocksList: null,

        selectedAIO2Count: 0,
        selectedExt24DinCount: 0,

        selectedEncoder: null,
        selectedInterface: null,
    },
    getters: {
        expandBlocksList: state => state.expandBlocksList,

        selectedAIO2Count: state => state.selectedAIO2Count,
        selectedExt24DinCount: state => state.selectedExt24DinCount,

        selectedEncoder: state => state.selectedEncoder,
        selectedInterface: state => state.selectedInterface,
    },
    mutations: {
        setExpandBlocksList(state, payload) {
            state.expandBlocksList = payload;
        },

        setSelectedAIO2Count(state, payload) {
            state.selectedAIO2Count = payload;
        },
        setSelectedExt24DinCount(state, payload) {
            state.selectedExt24DinCount = payload;
        },

        setSelectedEncoder(state, payload) {
            state.selectedEncoder = payload;
        },
        setSelectedInterface(state, payload) {
            state.selectedInterface = payload;
        },
    },
    actions: {
        async loadExpandBlocksList({commit}) {
            const response = await BACKEND_API.get('expand_blocks/');
            commit('setExpandBlocksList', response.data);
        },

        setSelectedAIO2Count({commit}, value) {
            commit('setSelectedAIO2Count', value);
        },
        setSelectedExt24DinCount({commit}, value) {
            commit('setSelectedExt24DinCount', value);
        },

        setSelectedEncoder({commit}, value) {
            commit('setSelectedEncoder', value);
        },
        setSelectedInterface({commit}, value) {
            commit('setSelectedInterface', value);
        },
    }
}
