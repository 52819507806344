export function eqSet(set1, set2) {
    if (set1.size !== set2.size) return false;
    for (const value of set1) if (!set2.has(value)) return false;
    return true;
}

export function intersectionSet(setA, setB) {
    let _intersection = new Set();
    for (let elem of setB) {
        if (setA.has(elem)) {
            _intersection.add(elem);
        }
    }
    return _intersection;
}


// 'Temperature' functions
export function fromCtoF(value) {
    return value * 9 / 5 + 32;
}

export function fromFtoC(value) {
    return (value - 32) * 5 / 9;
}


// 'Power' functions
export function powerFromLabelToNumber(humanizedPower) {
    let value = 0;

    let kilowatt = 'K';
    let megawatt = 'M';
    let measures = [kilowatt, megawatt];

    if (measures.some(measure => humanizedPower.includes(measure))) {

        if (measures.includes(humanizedPower[0])) {
            value = parseInt(humanizedPower.substring(1)) / 100;
        } else if (measures.includes(humanizedPower[2])) {
            value = parseInt(humanizedPower.substring(0, 2));
        } else {
            value = parseInt(humanizedPower[0] + humanizedPower[2]) / 10;
        }
    }
    if (humanizedPower.includes(megawatt)) {
        value *= 1000;
    }

    return value;
}
