export const NO_PAGE = -1;

export const PAGE_ZERO = 0;
export const PAGE_FIRST = 1;
export const PAGE_SECOND = 2;
export const PAGE_THIRD = 3;
export const PAGE_FOURTH = 4;
export const PAGE_FIFTH = 5;
export const PAGE_SIXTH = 6;
export const PAGE_SEVENTH = 7;

export const LAST_PAGE = PAGE_SEVENTH;

export const PAGES_TOTAL = PAGE_SEVENTH;
