<template>
    <section class="nomenclature_wrapper">
        <h2 class="nomenclature">
            <span class="nomenclature_indent">{{ $t('general.nomenclature') }}</span>
            <span>{{ nomenclatureFC }}</span>
        </h2>
    </section>
</template>

<script>
    import NomenclatureMixin from "../mixins/NomenclatureMixin";

    export default {
        name: "Nomenclature",
        mixins: [NomenclatureMixin],
    }
</script>

<style scoped>

</style>
