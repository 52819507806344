import {BACKEND_API} from "../axios-config";

export default {
    state: {
        // list of objects in the format [{id: Number, value: Number, char: String}, ...].
        // e.g. [{id: 1, value: 380, char: '4'}, {id: 2, value: 690, char: '7'}]
        voltagesList: null,
        voltage: null,  // selected value ('id' key)
    },
    getters: {
        voltagesList: state => state.voltagesList,
        voltage: state => state.voltage,
        voltageValue: state => {
            if (!state.voltage || !state.voltagesList)
                return null;

            let voltage = state.voltagesList.find(elem => elem.id === state.voltage);
            return voltage ? voltage.value : null;
        },
        voltageChar: state => state.voltagesList.find(v => v.id === state.voltage).char,
    },
    mutations: {
        setVoltage(state, payload) {
            state.voltage = payload;
        },
        setVoltagesList(state, payload) {
            state.voltagesList = payload;
        },
    },
    actions: {
        setVoltage({commit}, value) {
            commit('setVoltage', value);
        },
        async loadVoltagesList({commit}) {
            const response = await BACKEND_API.get('voltages/');
            commit('setVoltagesList', response.data);
        },
    }
}
