<template>
    <div class="сomponent-wrapper">
        <triol-header/>
        <triol-link/>
        <form class="main">
            <section class="main_content">
                
                <h2 class="main_headline">{{ $t('general.network_voltage') }}</h2>
                <radio-select v-model="voltage" :options="voltagesList" :class="{ 'checkbox_wrapper__error': $v.voltage.$error }"
                              option-key-name="id" option-text-name="value" checkbox-class="checkbox__width"
                              unique-element-key="voltage" :disabled-selected="true" />
            </section>
            <section class="main_content">
                <h2 class="main_headline">{{ $t('general.engine_type') }}</h2>
                <common-select :options="engineTypes" :disabled="disabledEngineType" v-model="engineType"/>
            </section>
            <span class="phase">{{ $t('general.phase_count')}}: <span>3</span></span>
            <section class="main_content">
                <h2 class="main_headline">{{ $t('general.engine_parameters') }}</h2>
                <div class="engine_wrapper">
                    <div class="engine_box">
                        <div class="engine">
                            <label class="engine_parameters" for="current">{{ $t('general.current_label') }}</label>
                            <div class="engine_input-wrapper engine_input-wrapper__100px">
                                <input class="engine_input" v-model="userFlow"
                                       :class="{ 'engine_input__error': $v.numberUserFlow.$error
                                       || $v.flowAndPowerValidation.$error || flowOutOfRangeError || $v.minFlowForValidation.$error || $v.maxFlowForValidation.$error}"
                                       :placeholder="$t('general.placeholder')" id="current"
                                       :disabled="!voltage"
                                       v-maska="maskFlow">
                                <span class="engine_input-decryption">{{ flowDescription }}</span>
                            </div>
                            <span  class="engine_third-element">А</span>
                        </div>
                        <div class="engine">
                            <label class="engine_parameters" for="power">{{ $t('general.power') }}</label>
                            <div class="engine_input-wrapper engine_input-wrapper__100px">
                                <input class="engine_input" v-model="userPower"
                                       :class="{ 'engine_input__error': $v.numberUserPowerAlwaysInKiloWatt.$error
                                       || $v.flowAndPowerValidation.$error || flowOutOfRangeError || $v.minFlowForValidation.$error || $v.maxFlowForValidation.$error}"
                                       :placeholder="$t('general.placeholder')" id="power"
                                       :disabled="!voltage"
                                       v-maska="maskPower">
                                <span class="engine_input-decryption">{{ powerDescription }}</span>
                            </div>
                            <common-select select-class="select_min" class="engine_third-element" v-model="selectedPowerMeasure" :options="powerMeasures"/>
                        </div>
                    </div>
                    <div class="engine_box">
                        <div class="engine">
                            <label class="engine_parameters" for="efficiency">{{ $t('general.efficiency') }}</label>
                            <div class="engine_input-wrapper engine_input-wrapper__100px">
                                <input class="engine_input" v-model="efficiency" :class="{ 'engine_input__error': $v.efficiency.$error
                                || flowOutOfRangeError || $v.minFlowForValidation.$error || $v.maxFlowForValidation.$error}"
                                    :placeholder="$t('general.placeholder')" id="efficiency"
                                    :disabled="!voltage"
                                    v-maska="{mask: ['Z#', 'TNN'],
                                    tokens: {'Z': {pattern: /[1-9]/}, 'T': {pattern: /1/}, 'N': {pattern: /0/}}}">
                                <span class="engine_input-decryption">{{ convertDescription("60-99") }}</span>
                            </div>
                            <span class="engine_third-element">%</span>
                        </div>
                        <div class="engine">
                            <label class="engine_parameters" for="cosine">{{ $t('general.cos') }}</label>
                            <div class="engine_input-wrapper engine_input-wrapper__100px">
                                <input class="engine_input" v-model="cos" :class="{ 'engine_input__error': $v.numberCos.$error
                                || flowOutOfRangeError || $v.minFlowForValidation.$error || $v.maxFlowForValidation.$error}"
                                    :placeholder="$t('general.placeholder')" id="cosine"
                                    :disabled="!voltage"
                                    v-maska="{mask: maskCos, tokens: {'N': {pattern: /0/}}}">
                                <span class="engine_input-decryption">{{ convertDescription("0.6-0.99") }}</span>
                            </div>
                            <span class="engine_third-element"/>
                        </div>
                    </div>
                </div>
            </section>
            <nomenclature/>
            <div class="block-hidden"></div>
        </form>
        <progress-navigator @previous="previous" @next="submit" :current-page="currentPage" :error-text="errorText" class-disabled-back='footer_disabled-back'/>
    </div>
</template>

<script>
    import RadioSelect from "../RadioSelect";
    import CommonSelect from "../CommonSelect";
    import Nomenclature from "../Nomenclature";
    import ProgressNavigator from "../ProgressNavigator";
    import PowerManager from '../../utils/power-manager'
    import TriolHeader from "../TriolHeader";
    import {required, integer, between, minValue, maxValue} from 'vuelidate/lib/validators'
    import {PAGE_FIRST, PAGE_SECOND, PAGE_ZERO} from "../../constants/page-constants";
    import {
        FLOW_MAX_VALUE_BY_LINE_MAP, FLOW_MAX_VALUE_BY_VOLTAGE_MAP,
        FLOW_MIN_VALUE_BY_LINE_MAP, FLOW_MIN_VALUE_BY_VOLTAGE_MAP, FLOW_SUPPLY_AS_A_PERCENTAGE,
    } from "../../constants/power-constants";
    import {powerFromLabelToNumber} from "../../utils/utils";
    import LocaleWatchMixin from "../../mixins/LocaleWatchMixin";
    import ErrorScrollMixin from "../../mixins/ErrorScrollMixin";
    import TriolLink from "../TriolLink";
    import DescriptionInputMixin from "../../mixins/DescriptionInputMixin";
    import {config} from "../../config";
    import {VOLTAGE_VALUE_MAP} from "../../constants/voltage-constants";

    export default {
        name: "Page1",
        components: {TriolHeader,Nomenclature, CommonSelect, RadioSelect, ProgressNavigator, TriolLink},
        mixins: [LocaleWatchMixin, ErrorScrollMixin, DescriptionInputMixin],
        validations() {
            let validators = {
                voltage: {required, integer},
                numberUserPowerAlwaysInKiloWatt: {},
                numberUserFlow: {},

                efficiency: {between: between(60, 99)},
                numberCos: {between: between(0.6, 0.99)},

                flowAndPowerValidation: {},
                minFlowForValidation: {},
                maxFlowForValidation: {},
            };
            if (!this.numberUserFlow) {
                validators.numberUserPowerAlwaysInKiloWatt.required = required;
                // validators.numberUserPowerAlwaysInKiloWatt.between = between(this.currentPowerMeasureObject.minValue, this.currentPowerMeasureObject.maxValue);
            }
            if (!this.numberUserPowerAlwaysInKiloWatt) {
                validators.numberUserFlow.required = required;
                validators.numberUserFlow.between = between(0.4, 3200);
            }
            if (this.flowAndPowerValidation) {
                validators.flowAndPowerValidation.between = between(0.4, 0.95);
            }

            let line = this.checkUniqueValueByFieldName('line', PAGE_FIRST);
            if (line && this.voltage) {
                let voltageValue = VOLTAGE_VALUE_MAP[this.$store.getters.voltageValue];

                if (this.minFlowForValidation) {
                    validators.minFlowForValidation.minValue = minValue(FLOW_MIN_VALUE_BY_LINE_MAP[line][voltageValue]);
                }
                if (this.maxFlowForValidation) {
                    validators.maxFlowForValidation.maxValue = maxValue(FLOW_MAX_VALUE_BY_LINE_MAP[line][voltageValue] * (1 + FLOW_SUPPLY_AS_A_PERCENTAGE / 100));
                }
            }

            return validators;
        },
        data() {
            return {
                flowOutOfRangeError: false,

                currentPage: PAGE_FIRST,

                userFlow: '',
                userPower: '',
                efficiency: '',
                cos: '',

                // TODO: move to the Back-End
                powerMeasures: [
                    {'value': 1, 'text': 'measures.power.kilowatt', 'koef': 1, 'minValue': 0.15, 'maxValue': 3200},
                    {'value': 2, 'text': 'measures.power.horse_power', 'koef': 1.35962, 'minValue': 0.15, 'maxValue': 4350}
                ],
                engineTypes: [
                    {value: false, text: 'general.engine_type.async'},
                    {value: true, text: 'general.engine_type.sync_full'},
                ],
            }
        },
        watch: {
            userFlow() {
                this.flowOutOfRangeError = false;
                this.$v.flowAndPowerValidation.$reset();
            },
            userPower() {
                this.flowOutOfRangeError = false;
                this.$v.flowAndPowerValidation.$reset();
            },
            efficiency() {
                this.flowOutOfRangeError = false;
            },
            cos() {
                this.flowOutOfRangeError = false;
            },
        },
        methods: {
            resetDataFromNextPages() {
                this.$store.dispatch('resetAfterPage1');
            },

            getBoundFieldValueByLine(fieldName, min = true) {
                let fcList = this.getFCList(PAGE_ZERO);
                let resultValues = {};

                for (const fc of fcList) {
                    if (!Object.prototype.hasOwnProperty.call(resultValues, fc.line)) {
                        resultValues[fc.line] = {};
                    }
                    if (!Object.prototype.hasOwnProperty.call(resultValues[fc.line], fc.voltage)) {
                        resultValues[fc.line][fc.voltage] = {field: fc[fieldName], power: powerFromLabelToNumber(fc.power)};
                        continue;
                    }

                    let currentPower = powerFromLabelToNumber(fc.power);
                    if (min && currentPower < resultValues[fc.line][fc.voltage].power
                    || !min && currentPower > resultValues[fc.line][fc.voltage].power) {
                        resultValues[fc.line][fc.voltage] = {field: fc[fieldName], power: currentPower};
                    }
                }
                for (const [line, values] of Object.entries(resultValues)) {
                    for (const [voltage, value] of Object.entries(values)) {
                        resultValues[line][voltage] = value.field;
                    }
                }
                return resultValues;
            },

            getUserPowerForCalculate() {
                return this.numberUserPower / this.currentPowerMeasureObject.koef;
            },
            async submit() {
                if (this.$v.$invalid) {
                    // TODO: display errors
                    await this.$v.$touch();
                    this.errorScrollFC()
                    return;
                }

                if (this.$store.getters.pageNumber < PAGE_FIRST) {
                    let powerManager = new PowerManager(this.numberUserFlow, this.getUserPowerForCalculate(), this.efficiency,
                        this.numberCos, this.$store.getters.voltageValue);

                    let [flow, power, efficiency, ] = powerManager.getFullInfo();

                    this.$store.dispatch('setUserFlow', this.userFlow);
                    this.$store.dispatch('setUserPower', this.userPower);

                    this.$store.dispatch('setFlow', flow);
                    this.$store.dispatch('setPower', power);
                    this.$store.dispatch('setEfficiency', efficiency);
                    this.$store.dispatch('setCos', this.numberCos);

                    if (this.getFCList(PAGE_FIRST).length === 0) {
                        this.flowOutOfRangeError = true;

                        this.$store.dispatch('resetAfterPage1');
                        this.$store.dispatch('setPageNumber', PAGE_ZERO);
                        return;
                    }
                    this.$store.dispatch('setPageNumber', PAGE_FIRST);
                }
                this.$router.push({name: `page${PAGE_SECOND}`});
            },
            previous() {
                // TODO: add handler logic
            },
            getFcListForDescription() {
                let fcList = this.fullFCList;
                if (!fcList) {
                    return;
                }
                fcList = this.filterListByFieldNameIfList(fcList, 'voltages_ids', this.$store.getters.voltage);

                if (this.$store.getters.engineType) {
                    fcList = this.simpleFilterListByFieldName(fcList, 'is_sync_engine_type', this.$store.getters.engineType);
                }
                return fcList;
            }
        },
        computed: {
            errorText() {
                if (this.$v.flowAndPowerValidation.$error) {
                    return 'error.power_and_flow_are_incorrect';
                } else if (this.$v.minFlowForValidation.$error || this.$v.maxFlowForValidation.$error || this.flowOutOfRangeError) {
                    return 'error.out_of_flow_range';
                }
                return '';
            },
            maskCos() {
                let value = 'N,##';
                for (const [from, to] of Object.entries(config.DESCRIPTION_INPUT_DELIMITER[this.$route.params.lang])) {
                    value = value.replaceAll(from, to);
                }
                return value;
            },
            maskPower() {
                let value = '#*,##';
                for (const [from, to] of Object.entries(config.DESCRIPTION_INPUT_DELIMITER[this.$route.params.lang])) {
                    value = value.replaceAll(from, to);
                }
                return value;
            },
            maskFlow() {
                let value = '#*,##';
                for (const [from, to] of Object.entries(config.DESCRIPTION_INPUT_DELIMITER[this.$route.params.lang])) {
                    value = value.replaceAll(from, to);
                }
                return value;
            },
            flowDescription() {
                if (!this.voltage) {
                    return;
                }
                const fcList = this.getFcListForDescription();

                let line = fcList && fcList.length && fcList.every(fc => fc['line'] === fcList[0]['line']) ? fcList[0]['line'] : null;

                const voltageValue = VOLTAGE_VALUE_MAP[this.$store.getters.voltageValue];
                let minValue = null;
                let maxValue = null;

                if (!line) {
                    minValue = FLOW_MIN_VALUE_BY_VOLTAGE_MAP[voltageValue];
                    maxValue = FLOW_MAX_VALUE_BY_VOLTAGE_MAP[voltageValue];
                } else {
                    minValue = FLOW_MIN_VALUE_BY_LINE_MAP[line][voltageValue];
                    maxValue = FLOW_MAX_VALUE_BY_LINE_MAP[line][voltageValue];
                }
                return this.convertDescription(`${minValue}-${maxValue}`);
            },
            powerDescription() {
                if (!this.voltage) {
                    return;
                }
                let minValue = this.minPowerForDescription;
                let maxValue = this.maxPowerForDescription;

                if (minValue === null || maxValue === null) {
                    minValue = 0.15 * this.currentPowerMeasureObject.koef;
                    maxValue = 1600 * this.currentPowerMeasureObject.koef;
                } else {
                    minValue = minValue * this.currentPowerMeasureObject.koef;
                    maxValue = maxValue * this.currentPowerMeasureObject.koef;
                }

                if (minValue < 1) {
                    minValue = Math.ceil(minValue * 100) / 100;
                } else if (minValue < 10) {
                    minValue = Math.ceil(minValue * 10) / 10;
                } else {
                    minValue = Math.ceil(minValue);
                }

                if (maxValue < 1) {
                    maxValue = Math.floor(maxValue * 100) / 100;
                } else if (maxValue < 10) {
                    maxValue = Math.floor(maxValue * 10) / 10;
                } else {
                    maxValue = Math.floor(maxValue);
                }

                return this.convertDescription(`${minValue}-${maxValue}`);
            },

            disabledEngineType() {
                if (!this.voltage) {
                    return true;
                }

                let fcList = this.filterListByFieldNameIfList(this.getFCList(PAGE_ZERO), 'voltages_ids', this.$store.getters.voltage);

                return !this.getUniqueValuesByFieldName(fcList, 'is_sync_engine_type').includes(true);
            },

            flowAndPowerValidation() {
                if (this.numberUserFlow && this.numberUserPower && this.voltage) {
                    return 1000 * this.getUserPowerForCalculate() / (this.numberUserFlow * this.$store.getters.voltageValue * Math.sqrt(3));
                } else {
                    return null;
                }
            },

            selectedPowerMeasure: {
                get() {
                    return this.$store.getters.userPowerMeasure;
                },
                set(value) {
                    this.$store.dispatch('setUserPowerMeasure', value)
                }
            },
            currentPowerMeasureObject() {
                return this.selectedPowerMeasure ? this.powerMeasures.find(measure => measure.value === this.selectedPowerMeasure) : null;
            },

            voltagesList() {
                let voltagesList = [...this.$store.getters.voltagesList];

                for (const voltage of voltagesList) {
                    let fcList = this.filterListByFieldNameIfList(this.fullFCList, 'voltages_ids', voltage.id);
                    if (this.engineType) {
                        fcList = this.simpleFilterListByFieldName(fcList, 'is_sync_engine_type', this.engineType);
                    }
                    voltage.disabled = fcList.length === 0;
                }

                return voltagesList;
            },
            voltage: {
                get() {
                    return this.$store.getters.voltage;
                },
                set(value) {
                    this.$store.dispatch('setVoltage', value);
                }
            },

            engineType: {
                get() {
                    return this.$store.getters.engineType;
                },
                set(value) {
                    this.$store.dispatch('setEngineType', value);
                }
            },

            numberCos() {
                return this.cos ? this.cos.replace(',', '.') : null;
            },
            numberUserFlow() {
                return this.userFlow ? this.userFlow.replace(',', '.') : '';
            },
            numberUserPower() {
                return this.userPower ? this.userPower.replace(',', '.') : '';
            },

            // used in validation block only
            minFlowForValidation() {
                let line = this.checkUniqueValueByFieldName('line', PAGE_FIRST);
                if (line && this.voltage) {
                    if (this.numberUserFlow) {
                        return this.numberUserFlow;
                    }

                    let voltageChar = this.$store.getters.voltageChar;

                    let efficiency = this.efficiency || this.getBoundFieldValueByLine('efficiency', true)[line][voltageChar];
                    let cos = this.numberCos || this.getBoundFieldValueByLine('cos', true)[line][voltageChar];

                    let powerManager = new PowerManager(null, this.getUserPowerForCalculate(), efficiency,
                        cos, this.$store.getters.voltageValue);

                    return powerManager.getFullInfo()[0];
                }
                return this.numberUserFlow;
            },
            // used in validation block only
            maxFlowForValidation() {
                let line = this.checkUniqueValueByFieldName('line', PAGE_FIRST);
                if (line && this.voltage) {
                    if (this.numberUserFlow) {
                        return this.numberUserFlow;
                    }

                    let voltageChar = this.$store.getters.voltageChar;

                    let efficiency = this.efficiency || this.getBoundFieldValueByLine('efficiency', false)[line][voltageChar];
                    let cos = this.numberCos || this.getBoundFieldValueByLine('cos', false)[line][voltageChar];

                    let powerManager = new PowerManager(null, this.getUserPowerForCalculate(), efficiency,
                        cos, this.$store.getters.voltageValue);

                    return powerManager.getFullInfo()[0];
                }
                return this.numberUserFlow;
            },

            minPowerForDescription() {
                const fcList = this.getFcListForDescription();
                let line = fcList && fcList.length && fcList.every(fc => fc['line'] === fcList[0]['line']) ? fcList[0]['line'] : null;

                if (line && this.voltage) {
                    const voltageValue = VOLTAGE_VALUE_MAP[this.$store.getters.voltageValue];
                    let flow = FLOW_MIN_VALUE_BY_LINE_MAP[line][voltageValue];

                    let voltageChar = this.$store.getters.voltageChar;

                    let efficiency = this.efficiency || this.getBoundFieldValueByLine('efficiency', true)[line][voltageChar];
                    let cos = this.numberCos || this.getBoundFieldValueByLine('cos', true)[line][voltageChar];

                    let powerManager = new PowerManager(flow, null, efficiency,
                        cos, this.$store.getters.voltageValue);

                    return powerManager.calculatePower();
                }
                return null;
            },
            maxPowerForDescription() {
                const fcList = this.getFcListForDescription();
                let line = fcList && fcList.length && fcList.every(fc => fc['line'] === fcList[0]['line']) ? fcList[0]['line'] : null;

                if (line && this.voltage) {
                    const voltageValue = VOLTAGE_VALUE_MAP[this.$store.getters.voltageValue];
                    let flow = FLOW_MAX_VALUE_BY_LINE_MAP[line][voltageValue];

                    let voltageChar = this.$store.getters.voltageChar;

                    let efficiency = this.efficiency || this.getBoundFieldValueByLine('efficiency', false)[line][voltageChar];
                    let cos = this.numberCos || this.getBoundFieldValueByLine('cos', false)[line][voltageChar];

                    let powerManager = new PowerManager(flow, null, efficiency,
                        cos, this.$store.getters.voltageValue);

                    return powerManager.calculatePower();
                }
                return null;
            },

            // used in validation block only
            numberUserPowerAlwaysInKiloWatt() {
                return this.getUserPowerForCalculate();
            }
        },
        created() {
            if (this.$store.getters.pageNumber < PAGE_ZERO) {
                this.$router.push({name: `page${this.$store.getters.pageNumber + 1}`});
            }

            this.userFlow = this.$store.getters.userFlow;
            this.userPower = this.$store.getters.userPower;
            this.efficiency = this.$store.getters.efficiency;
            this.cos = this.$store.getters.cos ? this.$store.getters.cos.replace('.', ',') : null;
        },
        beforeUpdate() {
            if (!this.disabledUpdateData) {
                this.resetDataFromNextPages();

                this.$store.dispatch('setUserFlow', '');
                this.$store.dispatch('setUserPower', '');
                this.$store.dispatch('setFlow', null);
                this.$store.dispatch('setPower', null);
            }
            this.disabledUpdateData = false;
        }
    }
</script>

<style scoped lang='scss'>

</style>
