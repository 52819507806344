<template>
    <footer class="footer">
        <div class="error_message" v-if="errorText"><span>{{ $t(errorText) }}</span></div>
        <div class="footer_wrapper">
            <button class="footer_btn footer_btn__black" @click="previous" type="button" aria-label="back" :class="classDisabledBack">
                {{ $t('general.previous') }}
            </button>
            <ul class="pagination">
                <li
                    v-for="page in pages"
                    class="pagination_item"
                    :key="page.name"
                >
                    <button
                        class="pagination_btn"
                        type="button"
                        @click="onClickPage(page.name)"
                        :disabled="page.isDisabled || isPageActive(page.name)"
                        :class="{ pagination_btn__active: isPageActive(page.name) }"
                    >{{ page.name }}</button>
                </li>
            </ul>
            <button class="footer_btn" @click="next" :type="[nextIsSubmit ? 'submit' : 'button']" aria-label="forward" :class="classDisabled">
                {{ $t('general.next') }}
            </button>
        </div>
    </footer>
</template>

<script>
    import {PAGES_TOTAL} from "../constants/page-constants";

    export default {
        name: "ProgressNavigator",
        props: {
            nextIsSubmit: {
                type: Boolean,
                default: false
            },
            classDisabledBack: String,
            classDisabled: String,
            currentPage: {
                type: Number,
                required: true
            },

            errorText: String,
        },
        computed: {
            startPage() {
                return 1;
            },
            endPage() {
                return this.totalPages;
            },
            pages() {
                const range = [];

                for (let i = this.startPage; i <= this.endPage; i += 1) {
                    range.push({
                        name: i,
                        isDisabled: i > this.$store.getters.pageNumber + 1
                    });
                }

                return range;
            }
        },
        methods: {
            next() {
                this.$emit('next')
            },
            previous() {
                this.$emit('previous')
            },
            isPageActive(page) {
                return this.currentPage === page;
            },
            onClickPage(pageNumber) {
                this.$router.push({name: `page${pageNumber}`});
            }
        },
        data() {
            return {
                totalPages: PAGES_TOTAL,
            }
        }
    }
</script>

<style scoped>

</style>
