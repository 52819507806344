import {DEFAULT_ENGINE_TYPE_VALUE} from "../constants/power-constants";

export default {
    state: {
        userFlow: '',
        userPower: '',
        userPowerMeasure: 1,

        flow: null,
        power: null,
        efficiency: '',
        cos: '',

        engineType: DEFAULT_ENGINE_TYPE_VALUE,
    },
    getters: {
        userFlow: state => state.userFlow,
        userPower: state => state.userPower,
        userPowerMeasure: state => state.userPowerMeasure,

        flow: state => state.flow,
        power: state => state.power,
        efficiency: state => state.efficiency,
        cos: state => state.cos,

        engineType: state => state.engineType,
    },
    mutations: {
        setUserFlow(state, payload) {
            state.userFlow = payload;
        },
        setUserPower(state, payload) {
            state.userPower = payload;
        },
        setUserPowerMeasure(state, payload) {
            state.userPowerMeasure = payload;
        },

        setFlow(state, payload) {
            state.flow = payload;
        },
        setPower(state, payload) {
            state.power = payload;
        },
        setEfficiency(state, payload) {
            state.efficiency = payload;
        },
        setCos(state, payload) {
            state.cos = payload;
        },

        setEngineType(state, payload) {
            state.engineType = payload;
        },
    },
    actions: {
        setUserFlow({commit}, value) {
            commit('setUserFlow', value);
        },
        setUserPower({commit}, value) {
            commit('setUserPower', value);
        },
        setUserPowerMeasure({commit}, value) {
            commit('setUserPowerMeasure', value);
        },

        setFlow({commit}, value) {
            commit('setFlow', value);
        },
        setPower({commit}, value) {
            commit('setPower', value);
        },
        setEfficiency({commit}, value) {
            commit('setEfficiency', value);
        },
        setCos({commit}, value) {
            commit('setCos', value);
        },

        setEngineType({commit}, value) {
            commit('setEngineType', value);
        },
    }
}
