import {RT_LINE, SD_LINE} from "./constants";

export const INPUT_MODE = 'input';
export const OUTPUT_MODE = 'output';

export const O1_DEFAULT_M2M_MODE = 'default';
export const O1_INTERNAL_M2M_MODE = 'internal';
export const O1_EXTERNAL_M2M_MODE = 'external';

export const DROSSEL_ZPT_OPTION1 = 1;
export const NETWORK_OPTION1 = 2;
export const DU_DT_OPTION1 = 3;
export const SINUS_OPTION1 = 4;
export const PASSIVE_OPTION1 = 5;
export const EMS_OPTION1 = 6;
export const SINPHASE_OPTION1 = 7;

export const O1_FILTERS_VALUES_MAP = {
    '0': new Set(),
    '1': new Set([DROSSEL_ZPT_OPTION1]),
    '2': new Set([NETWORK_OPTION1]),
    '3': new Set([DU_DT_OPTION1]),
    '4': new Set([SINUS_OPTION1]),
    '5': new Set([PASSIVE_OPTION1]),
    '6': new Set([EMS_OPTION1]),
    '7': new Set([SINPHASE_OPTION1]),

    'A': new Set([DROSSEL_ZPT_OPTION1, DU_DT_OPTION1]),
    'B': new Set([DROSSEL_ZPT_OPTION1, SINUS_OPTION1]),
    'C': new Set([DROSSEL_ZPT_OPTION1, EMS_OPTION1]),
    'D': new Set([NETWORK_OPTION1, DU_DT_OPTION1]),
    'E': new Set([NETWORK_OPTION1, SINUS_OPTION1]),
    'F': new Set([NETWORK_OPTION1, EMS_OPTION1]),
    'G': new Set([DU_DT_OPTION1, PASSIVE_OPTION1]),
    'H': new Set([DROSSEL_ZPT_OPTION1, SINPHASE_OPTION1]),
    'I': new Set([SINUS_OPTION1, PASSIVE_OPTION1, EMS_OPTION1]),
    'J': new Set([PASSIVE_OPTION1, EMS_OPTION1]),
    'K': new Set([DROSSEL_ZPT_OPTION1, DU_DT_OPTION1, EMS_OPTION1]),
    'L': new Set([DROSSEL_ZPT_OPTION1, SINUS_OPTION1, EMS_OPTION1]),
    'M': new Set([NETWORK_OPTION1, DU_DT_OPTION1, EMS_OPTION1]),
    'N': new Set([NETWORK_OPTION1, SINUS_OPTION1, EMS_OPTION1]),
    'P': new Set([DU_DT_OPTION1, PASSIVE_OPTION1, EMS_OPTION1]),
    'Q': new Set([NETWORK_OPTION1, SINPHASE_OPTION1]),
    'R': new Set([DROSSEL_ZPT_OPTION1, DU_DT_OPTION1, SINPHASE_OPTION1]),
    'S': new Set([DROSSEL_ZPT_OPTION1, EMS_OPTION1, SINPHASE_OPTION1]),
    'T': new Set([NETWORK_OPTION1, DU_DT_OPTION1, EMS_OPTION1, SINPHASE_OPTION1]),
    'U': new Set([NETWORK_OPTION1, DU_DT_OPTION1, SINPHASE_OPTION1]),
    'V': new Set([SINUS_OPTION1, PASSIVE_OPTION1]),
    'W': new Set([DROSSEL_ZPT_OPTION1, DU_DT_OPTION1, EMS_OPTION1, SINPHASE_OPTION1]),
    'X': new Set([NETWORK_OPTION1, EMS_OPTION1, SINPHASE_OPTION1])
};

export const MUTUALLY_EXCLUSIVE_OPTIONS1_MAP = {};
MUTUALLY_EXCLUSIVE_OPTIONS1_MAP[SD_LINE] = [
    new Set([DROSSEL_ZPT_OPTION1, NETWORK_OPTION1])
];
MUTUALLY_EXCLUSIVE_OPTIONS1_MAP[RT_LINE] = [
    new Set([DROSSEL_ZPT_OPTION1, NETWORK_OPTION1, PASSIVE_OPTION1]),
    new Set([DU_DT_OPTION1, SINUS_OPTION1])
];
