export const VOLTAGE_VALUE_MAP = {
    380: 380,
    400: 380,
    415: 380,

    440: 480,
    480: 480,

    660: 690,
    690: 690,
};
