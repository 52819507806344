<template>
    <div class="сomponent-wrapper" v-if="currentFC">
        <triol-header/>
        <triol-link/>
        <form class="main">
            <h2 class="main_headline">
                {{ $t('general.selecting_additional_options') }}<br>
                {{ $t('general.expansion_blocks') }}
            </h2>
            <section class="main_content main_content__flex">
                <div class="main_content-half main_content-half__70">
                    <div class="block">
                        <div class="block_wrapper">
                            <div class="block_text">{{ $t('general.block')}} {{ AIO2Block.name }}</div>
                            <div class="block_text">{{ AIO2FeatureInfo }}</div>
                        </div>
                        <counter-select v-model="selectedAIO2Count" :count="freeBlocksPlacesForAIO2Count"/>
                    </div>
                    <div class="error_block-main" v-show="!this.freeBlocksPlaces">
                        ({{ $t('error.expand_blocks.max_count_already') }})
                    </div>
                    <div class="block">
                        <div class="block_wrapper">
                            <div class="block_text">{{ $t('general.block')}} {{ EXT24DinBlock.name }}</div>
                            <div class="block_text">{{ EXT24DinFeatureInfo }}</div>
                        </div>
                        <counter-select v-model="selectedExt24DinCount" :count="freeBlocksPlacesForExt24DinCount"/>
                    </div>
                    <div class="error_block-main" v-show="!this.freeBlocksPlaces">
                        ({{ $t('error.expand_blocks.max_count_already') }})
                    </div>
                </div>
                <div class="main_content-half main_content-half__30">
                    <div class="select__mb-19">
                        <div class="select_label">{{ $t('general.encoder_block') }}</div>
                        <common-select :options="encoderBlocksList" v-model="selectedEncoder"
                                       :disabled="disabledEncoderSelect"
                                    with-empty option-key-name="id" option-text-name="name" />
                    </div>
                    <div class="error_block-select" v-show="!this.freeBlocksPlaces && !this.selectedEncoder">
                        ({{ $t('error.expand_blocks.max_count_already') }})
                    </div>
                    <div class="select__mb-19">
                        <div class="select_label">{{ $t('general.interface_block') }}</div>
                        <common-select :options="interfaceBlocksList" v-model="selectedInterface"
                                       :disabled="disabledInterfaceSelect"
                                    with-empty option-key-name="id" option-text-name="name" />
                    </div>
                    <div class="error_block-select" v-show="!this.freeBlocksPlaces && !this.selectedInterface">
                        ({{ $t('error.expand_blocks.max_count_already') }})
                    </div>
                    <p class="main_text">{{ $t('info.modbus_protocol') }}</p>
                </div>
            </section>
            <div class="main_border"></div>
            <h3 class="main_headline">{{ $t('general.configuration_with_selected_options') }}</h3>
            <section class="main_content options_content">
                <ul class="options_list">
                    <li class="options_item"><span  class="options_quantity">{{ programmaticDiscreteInputCount }}</span>
                        {{ $t('general.config.programmatic_discrete_input.' + getMaturity(programmaticDiscreteInputCount)) }};
                    </li>
                    <li class="options_item"><span  class="options_quantity">{{ impulseDiscreteInputCount }}</span>
                        {{ $t('general.config.impulse_discrete_input.' + getMaturity(impulseDiscreteInputCount)) }};
                    </li>
                    <li class="options_item"><span  class="options_quantity">{{ safetyInternetDisconnectionMomentsCount }}</span>
                        {{ $t('general.config.safety_internet_disconnection_moments_count.' + getMaturity(safetyInternetDisconnectionMomentsCount)) }};
                    </li>
                    <li class="options_item"><span  class="options_quantity">{{ relayOutputCount }}</span> {{ $t('general.config.relay_output.' + getMaturity(relayOutputCount)) }};</li>
                    <li class="options_item"><span  class="options_quantity">{{ impulseDiscreteOutputCount }}</span> {{ $t('general.config.impulse_discrete_output.' + getMaturity(impulseDiscreteOutputCount)) }};
                    </li>
                </ul>
                <ul class="options_list">
                    <li class="options_item"><span  class="options_quantity">{{ analogInputCount }}</span> {{ $t('general.config.analog_input.' + getMaturity(analogInputCount)) }};
                    </li>
                    <li class="options_item"><span  class="options_quantity">{{ analogOutputCount }}</span> {{ $t('general.config.analog_output.' + getMaturity(analogOutputCount)) }};
                    </li>
                    <li class="options_item" v-if="currentFC.two_wire_interface_count">{{ $t('general.config.two_wire_interface') }}.
                    </li>
                    <li class="options_item" v-if="selectedInterface">{{ $t('general.interface') }} {{ $t(selectedInterfaceObject.name) }}</li>
                </ul>
            </section>
            <div class="block-hidden"></div>
        </form>
        <progress-navigator @previous="previous" @next="next" :current-page="currentPage" />
    </div>
</template>

<script>
    import TriolHeader from "../TriolHeader";
    import {PAGE_FIFTH, PAGE_FOURTH, PAGE_SIXTH} from "../../constants/page-constants";
    import ProgressNavigator from "../ProgressNavigator";
    import {
        AIO2_ID,
        ENCODER_MODE,
        EXT24_DIN_ID,
        INTERFACE_MODE
    } from "../../constants/expand-blocks-constants";
    import CounterSelect from "../CounterSelect";
    import ExpandBlocksInfoManager from '../../utils/expand-blocks-info-manager';
    import CommonSelect from "../CommonSelect";
    import LocaleWatchMixin from "../../mixins/LocaleWatchMixin";
    import TriolLink from "../TriolLink";

    export default {
        name: "Page5",
        components: {CommonSelect, CounterSelect, ProgressNavigator, TriolHeader, TriolLink},
        mixins: [LocaleWatchMixin],
        data() {
            return {
                currentFC: null,

                currentPage: PAGE_FIFTH,
            }
        },
        methods: {
            getMaturity(count) {
                if (count === 1) {
                    return 'one';
                } else if (count < 5) {
                    return 'few';
                } else {
                    return 'many';
                }
            },
            resetDataFromNextPages() {
                this.$store.dispatch('resetAfterPage5');
            },
            previous() {
                this.$router.push({name: `page${PAGE_FOURTH}`});
            },
            next() {
                if (this.$store.getters.pageNumber < PAGE_FIFTH) {
                    this.$store.dispatch('setPageNumber', PAGE_FIFTH);
                }
                this.$router.push({name: `page${PAGE_SIXTH}`});
            },
            calculateBlockOptionCount(optionName) {
                let encoderValue = this.selectedEncoder
                    ? this.expandBlocksList.find(block => block.id === this.selectedEncoder)[optionName] : 0;
                let interfaceValue = this.selectedInterface
                    ? this.expandBlocksList.find(block => block.id === this.selectedInterface)[optionName] : 0;
                return this.currentFC[optionName]
                    + this.AIO2Block[optionName] * this.selectedAIO2Count
                    + this.EXT24DinBlock[optionName] * this.selectedExt24DinCount
                    + encoderValue + interfaceValue;
            },
        },
        computed: {
            expandBlocksList() {
                return this.$store.getters.expandBlocksList;
            },

            disabledEncoderSelect() {
                return !this.freeBlocksPlaces && !this.selectedEncoder;
            },
            disabledInterfaceSelect() {
                return !this.freeBlocksPlaces && !this.selectedInterface;
            },

            freeBlocksPlaces() {
                return this.currentFC.max_count_expand_blocks
                    - this.selectedAIO2Count
                    - this.selectedExt24DinCount
                    - !!this.selectedEncoder
                    - !!this.selectedInterface
            },

            freeBlocksPlacesForAIO2Count() {
                return this.currentFC.max_count_expand_blocks - this.selectedExt24DinCount - !!this.selectedEncoder - !!this.selectedInterface;
            },
            freeBlocksPlacesForExt24DinCount() {
                return this.currentFC.max_count_expand_blocks - this.selectedAIO2Count - !!this.selectedEncoder - !!this.selectedInterface;
            },
            AIO2Block() {
                return this.expandBlocksList.find(block => block.id === AIO2_ID);
            },
            /**
             * @return {string}
             */
            AIO2FeatureInfo() {
                let block = this.AIO2Block;
                return new ExpandBlocksInfoManager(block).getInfo();
            },

            EXT24DinBlock() {
                return this.expandBlocksList.find(block => block.id === EXT24_DIN_ID);
            },
            /**
             * @return {string}
             */
            EXT24DinFeatureInfo() {
                let block = this.EXT24DinBlock;
                return new ExpandBlocksInfoManager(block).getInfo();
            },

            encoderBlocksList() {
                return this.expandBlocksList.filter(block => block.mode === ENCODER_MODE);
            },
            selectedEncoder: {
                get() {
                    return this.$store.getters.selectedEncoder;
                },
                set(value) {
                    this.$store.dispatch('setSelectedEncoder', value);
                },
            },
            interfaceBlocksList() {
                return this.expandBlocksList.filter(block => block.mode === INTERFACE_MODE);
            },
            selectedInterface: {
                get() {
                    return this.$store.getters.selectedInterface;
                },
                set(value) {
                    this.$store.dispatch('setSelectedInterface', value);
                },
            },
            selectedInterfaceObject() {
                return this.interfaceBlocksList.find(block => block.id === this.selectedInterface);
            },

            programmaticDiscreteInputCount() {
                return this.calculateBlockOptionCount('programmatic_discrete_input_count');
            },
            impulseDiscreteInputCount() {
                return this.calculateBlockOptionCount('impulse_discrete_input_count');
            },
            safetyInternetDisconnectionMomentsCount() {
                return this.calculateBlockOptionCount('safety_internet_disconnection_moments_count');
            },
            relayOutputCount() {
                return this.calculateBlockOptionCount('relay_output_count');
            },
            impulseDiscreteOutputCount() {
                return this.calculateBlockOptionCount('impulse_discrete_output_count');
            },
            analogInputCount() {
                return this.calculateBlockOptionCount('analog_input_count');
            },
            analogOutputCount() {
                return this.calculateBlockOptionCount('analog_output_count');
            },

            selectedAIO2Count: {
                get() {
                    return this.$store.getters.selectedAIO2Count;
                },
                set(value) {
                    this.$store.dispatch('setSelectedAIO2Count', value);
                }
            },
            selectedExt24DinCount: {
                get() {
                    return this.$store.getters.selectedExt24DinCount;
                },
                set(value) {
                    this.$store.dispatch('setSelectedExt24DinCount', value);
                }
            },
        },
        created() {
            if (this.$store.getters.pageNumber < PAGE_FOURTH) {
                this.$router.push({name: `page${this.$store.getters.pageNumber + 1}`});
            }

            this.currentFC = this.getFC();
        },
        // beforeUpdate() {
        //     if (!this.disabledUpdateData) {
        //         this.resetDataFromNextPages();
        //     }
        //     this.disabledUpdateData = false;
        // }
    }
</script>

<style scoped lang="scss">
</style>
