import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuelidate from 'vuelidate'
import Maska from 'maska'
import {store} from "./store/store"
import {router} from "./router/router"
import FilteredFCListMixin from "./mixins/FilteredFCListMixin";
import "./assets/scss/main.scss"
import {i18n} from "./translations/translations";
import VueScrollTo from 'vue-scrollto'

Vue.use(Vuelidate);
Vue.use(VueRouter);
Vue.use(Maska);
Vue.use(VueScrollTo);

Vue.config.productionTip = false;

Vue.mixin(FilteredFCListMixin);

new Vue({
    store,
    i18n,
    router,
    render: h => h(App),
}).$mount('#app');
