import {i18n} from "../translations/translations";

class ExpandBlocksInfoManager {
    FIELDS = {
        'analog_input_count': 'general.analog_input',
        'analog_output_count': 'general.analog_output',
        'impulse_discrete_input_count': 'general.impulse_discrete_input',
        'impulse_discrete_output_count': 'general.impulse_discrete_output',
        'programmatic_discrete_input_count': 'general.programmatic_discrete_input',
        'safety_internet_disconnection_moments_count': 'general.safety_internet_disconnection_moments_count',
        'relay_output_count': 'general.relay_output',
    };

    constructor(expandBlockObject) {
        this.expandBlockObject = expandBlockObject;
    }
    getInfo(fullPrepend = '(', fullAppend = ')', separator = ', ', prefix = '+') {
        let resultInfo = [];

        for (const [fieldName, fieldText] of Object.entries(this.FIELDS)) {
            if (Object.prototype.hasOwnProperty.call(this.expandBlockObject, fieldName) && this.expandBlockObject[fieldName]) {
                const maturity = this.getMaturity(this.expandBlockObject[fieldName]);
                resultInfo.push(prefix + this.expandBlockObject[fieldName] + ' ' + i18n.t(fieldText + '.' + maturity));
            }
        }

        if (resultInfo.length === 0) {
            return '';
        } else {
            return fullPrepend + resultInfo.join(separator) + fullAppend;
        }
    }
    getMaturity(count) {
        if (count === 1) {
            return 'one';
        } else if (count < 5) {
            return 'few';
        } else {
            return 'many';
        }
    }
}

export default ExpandBlocksInfoManager;
