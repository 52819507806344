<template>
    <div @click="hideShowInfo">
        <error v-show="showError"/>
        <router-view/>
    </div>
</template>

<script>
    import Error from "./components/Error";

    export default {
        name: 'App',
        components: {Error},
        data() {
            return {
                resetInfoBox: true
            }
        },
        computed: {
            showError() {
                return this.$store.getters.showGlobalError;
            },
        },
        methods: {
            loadDataFromBackEnd(actionName, getterName) {
                if (!this.$store.getters[getterName]) {
                    this.$store.dispatch(actionName);
                }
            },
            hideShowInfo() {
                if (this.resetInfoBox) {
                    this.$store.dispatch("setInfoBoxActive", null);
                }
                this.resetInfoBox = true;
            }
        },
        watch: {
            "$store.getters.infoBoxActive": function() {
                this.resetInfoBox = false
            }
        },
        created() {
            this.$root.$i18n.locale = this.$route.params.lang;

            // general
            this.loadDataFromBackEnd('loadFullFCList', 'fullFCList');

            // page1
            this.loadDataFromBackEnd('loadVoltagesList', 'voltagesList');

            // page2
            this.loadDataFromBackEnd('loadImplementationsList', 'implementationsList');
            this.loadDataFromBackEnd('loadToolsList', 'toolsList');
            this.loadDataFromBackEnd('loadModesOfOpsList', 'modesOfOpsList');

            // page3
            this.loadDataFromBackEnd('loadPlacementsList', 'placementsList');
            this.loadDataFromBackEnd('loadDegreesOfProtectionList', 'degreesOfProtectionList');
            this.loadDataFromBackEnd('loadMontageTypesList', 'montageTypesList');

            // page4
            this.loadDataFromBackEnd('loadOption1List', 'option1List');
            this.loadDataFromBackEnd('loadFCAndOption1List', 'fcAndOption1List');
            this.loadDataFromBackEnd('loadOption2List', 'option2List');
            this.loadDataFromBackEnd('loadFCAndOption2List', 'fcAndOption2List');

            // page5
            this.loadDataFromBackEnd('loadExpandBlocksList', 'expandBlocksList');

            // page6
            this.loadDataFromBackEnd('loadRemoteControlsList', 'remoteControlsList');
        }
    }
</script>

<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
</style>
