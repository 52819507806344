<template>
    <div class="checkbox_wrapper">
        <div class="checkbox_box checkbox_box__img">
            <label class="checkbox checkbox__flex" :class="[checkboxClass, {'checkbox__disabled' : disabled }, {'checkbox__active': checked}]">
                <input class="checkbox_radio" type="checkbox" :value="option[optionKeyName]" v-model="value"
                       :id="'check-' + uniqueElementKey" :disabled="disabled">
                <label class="checkbox_label" :for="'check-' + uniqueElementKey">
                    {{ $t(option[optionTextName]) }}
                </label>
            </label>
            <info-box v-if="option[infoTextName]" :class="infoBoxClass" :text-key="option[infoTextName]" :index="'check-' + uniqueElementKey"/>
        </div>
        <div class="error_block-main error_block-main__external-installation-option" v-show="warningText">({{ $t(warningText) }})</div>
    </div>
</template>

<script>
    import InfoBox from "./InfoBox"


    export default {
        name: "Checkbox",
        components: {InfoBox},
        props: {
            checked: null,

            option: Object,
            optionKeyName: {
                type: String,
                default: 'value',
            },
            optionTextName: {
                type: String,
                default: 'text',
            },
            checkboxClass: String,
            wrapperClass: String,
            infoBoxClass: String,

            uniqueElementKey: {
                type: String,
                default: '',
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            infoTextName: {
                type: String,
                default: 'infoText',
            },
            warningText: String,
        },
        data() {
            return {
                value: null,
            }
        },
        methods: {},
        watch: {
            value: function (newVal) {
                if (newVal) {
                    this.$emit('checked')
                } else {
                    this.$emit('unchecked')
                }
            },
            checked: {
                handler(newVal) {
                    this.value = newVal
                },
                immediate: true,
            }
        }
    }
</script>

<style scoped>

</style>
