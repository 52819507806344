<template>
    <div class="сomponent-wrapper">
        <triol-header/>
        <triol-link/>
        <form class="main">
            <section class="main_content main_content__800" v-if="inputOption1List.length && outputOption1List.length">
                <h2 class="main_headline">{{ $t('general.selecting_additional_options') }}</h2>
                <div v-if="inputOption1List.length">
                    <h2 class="main_headline">{{ $t('general.input_filters') }}</h2>
                    <div class="main_info main_info__mb-15">
                        <img class="main_info-image" src="@/assets/image/info_24px.svg" alt="">
                        <p class="main_info-text">{{ $t('info.c3_category') }}</p>
                    </div>
                    <checkbox v-for="(option, index) in inputOption1List" :key="'input-options-' + option.id"
                              @checked="selectOption1(option.id)" @unchecked="unselectOption1(option.id)"
                              :option="option" :checked="isSelectedOption1(option)"
                              :disabled="isSelectedOption1(option) && !canUnselectOption1(option.id)"
                              :warning-text="isSelectedOption1(option) && isExternalOption1(option) ? 'options.description.external': ''"
                              option-key-name="id" option-text-name="name" info-text-name="info_text"
                              :uniqueElementKey="'input-o1' + option.id" wrapper-class="checkbox_wrapper__center"
                              :info-box-class="index + 1 <= infoBoxDelimiterCount ? 'info-box__down' : ''"
                    />
                </div>
                <div v-if="outputOption1List.length">
                    <h2 class="main_headline">{{ $t('general.output_filters') }}</h2>
                    <checkbox v-for="(option, index) in outputOption1List" :key="'output-options-' + option.id"
                              @checked="selectOption1(option.id)" @unchecked="unselectOption1(option.id)"
                              :option="option" :checked="isSelectedOption1(option)"
                              :disabled="isSelectedOption1(option) && !canUnselectOption1(option.id)"
                              :warning-text="isSelectedOption1(option) && isExternalOption1(option) ? 'options.description.external': ''"
                              option-key-name="id" option-text-name="name" info-text-name="info_text"
                              :uniqueElementKey="'output-o1' + option.id" wrapper-class="checkbox_wrapper__center"
                              :info-box-class="inputOption1List.length + index + 1 <= infoBoxDelimiterCount ? 'info-box__down' : ''"
                    />
                </div>
            </section>
            <section class="main_content  main_content__800">
                <h2 class="main_headline">{{ $t('general.selecting_additional_options') }}<br>
                                          {{ $t('general.other_options_for_line') }}
                    <span class="main_headline__highlighted">{{ line }}</span>
                </h2>
                <checkbox v-for="(option, index) in localOption2List" :key="'options2-' + option.id"
                          @checked="selectOption2(option.id)" @unchecked="unselectOption2(option.id)"
                          :option="option" :checked="isSelectedOption2(option)"
                          :disabled="isSelectedOption2(option) && !canUnselectOption2(option.id)"
                          :warning-text="isSelectedOption2(option) && isExternalOption2(option) ? 'options.description.external': ''"
                          option-key-name="id" option-text-name="name" info-text-name="info_text"
                          :uniqueElementKey="'options2' + option.id"  wrapper-class="checkbox_wrapper__center"
                          :info-box-class="inputOption1List.length + outputOption1List.length + index + 1 <= infoBoxDelimiterCount ? 'info-box__down' : ''"
                />
            </section>
            <nomenclature/>
            <div class="block-hidden"></div>
        </form>
        <progress-navigator @previous="previous" @next="next" :current-page="currentPage" :error-text="errorText"/>
    </div>
</template>

<script>
    import ProgressNavigator from "../ProgressNavigator";
    import Nomenclature from "../Nomenclature";
    import TriolHeader from '../TriolHeader';
    import {PAGE_FIFTH, PAGE_FOURTH, PAGE_THIRD} from "../../constants/page-constants";
    import {
        O1_DEFAULT_M2M_MODE,
        INPUT_MODE,
        OUTPUT_MODE,
        MUTUALLY_EXCLUSIVE_OPTIONS1_MAP, O1_EXTERNAL_M2M_MODE
    } from "../../constants/option1-constants";
    import Checkbox from "../Checkbox";
    import {
        BORE_CONTROLLER_OPTION2_ID,
        ISOLATION_CONTROL_OPTION2_ID,
        O2_DEFAULT_M2M_MODE, O2_EXTERNAL_M2M_MODE
    } from "../../constants/option2-constants";
    import LocaleWatchMixin from "../../mixins/LocaleWatchMixin";
    import NomenclatureMixin from "../../mixins/NomenclatureMixin";
    import TriolLink from "../TriolLink";

    export default {
        name: "Page4",
        components: {Checkbox, Nomenclature, ProgressNavigator, TriolHeader, TriolLink},
        mixins: [LocaleWatchMixin, NomenclatureMixin],
        data() {
            return {
                currentPage: PAGE_FOURTH,

                showOptionsCombinationNotExistError: false,

                infoBoxDelimiterCount: 5,
            }
        },
        methods: {
            resetDataFromNextPages() {
                this.$store.dispatch('resetAfterPage4');
            },
            isSelectedOption1(option) {
                return this.$store.getters.selectedOptions1List.includes(option.id);
            },
            isExternalOption1(option) {
                let fcAndO1List = this.getFcAndO1ListByFCAndVoltage();

                if (!fcAndO1List) {
                    return false;
                }

                fcAndO1List = fcAndO1List.filter(o1 => o1.mode === O1_EXTERNAL_M2M_MODE);
                let fcAndO1Ids = this.getUniqueValuesByFieldName(fcAndO1List, 'option_id');

                return fcAndO1Ids.includes(option.id);
            },
            isDefaultOption1(option) {
                let fcAndO1List = this.getFcAndO1ListByFCAndVoltage();

                if (!fcAndO1List) {
                    return false;
                }

                fcAndO1List = fcAndO1List.filter(o1 => o1.mode === O1_DEFAULT_M2M_MODE);
                let fcAndO1Ids = this.getUniqueValuesByFieldName(fcAndO1List, 'option_id');

                return fcAndO1Ids.includes(option.id);
            },

            previous() {
                this.$router.push({name: `page${PAGE_THIRD}`});
            },
            next() {
                if (this.getOption1() === '_' || this.getOption2() === '_') {
                    this.showOptionsCombinationNotExistError = true;
                    return;
                }

                if (this.$store.getters.pageNumber < PAGE_FOURTH) {
                    this.$store.dispatch('setPageNumber', PAGE_FOURTH);
                }
                this.$router.push({name: `page${PAGE_FIFTH}`});
            },

            unselectExclusiveOption1(option1Id) {
                let selectedOption = this.option1List.find(o1 => o1.id === option1Id);

                for (const exclusiveValuesSet of MUTUALLY_EXCLUSIVE_OPTIONS1_MAP[this.getLine()] || []) {
                    if (exclusiveValuesSet.has(selectedOption.value)) {
                        let exclusiveIdsList = this.option1List.filter(o1 => exclusiveValuesSet.has(o1.value)).map(o1 => o1.id);

                        for (const option1 of exclusiveIdsList) {
                            if (option1 !== option1Id) {
                                this.$store.dispatch('unselectOption1', option1)
                            }
                        }
                    }
                }
            },
            checkGroupOption1HasDefault(option1List) {
                return option1List.filter(o1 => this.isDefaultOption1(o1)).length !== 0;
            },
            canUnselectOption1(option1Id) {
                let selectedOption = this.option1List.find(o1 => o1.id === option1Id);

                for (const exclusiveValuesSet of MUTUALLY_EXCLUSIVE_OPTIONS1_MAP[this.getLine()] || []) {
                    if (exclusiveValuesSet.has(selectedOption.value) && this.checkGroupOption1HasDefault(
                        this.option1List.filter(o1 => exclusiveValuesSet.has(o1.value))
                    )) {
                        return false;
                    }
                }
                return !this.isDefaultOption1(selectedOption);
            },
            selectOption1(value) {
                this.unselectExclusiveOption1(value);
                this.$store.dispatch('selectOption1', value);
            },
            unselectOption1(value) {
                if (this.canUnselectOption1(value)) {
                    this.$store.dispatch('unselectOption1', value);
                }
            },

            isDefaultOption2(option) {
                let fcAndO2List = this.getFcAndO2ListByFC();

                if (!fcAndO2List) {
                    return false;
                }

                fcAndO2List = fcAndO2List.filter(o2 => o2.mode === O2_DEFAULT_M2M_MODE);
                let fcAndO2Ids = this.getUniqueValuesByFieldName(fcAndO2List, 'option_id');

                return fcAndO2Ids.includes(option.id);
            },
            isExternalOption2(option) {
                let fcAndO2List = this.getFcAndO2ListByFC();

                if (!fcAndO2List) {
                    return false;
                }

                fcAndO2List = fcAndO2List.filter(o2 => o2.mode === O2_EXTERNAL_M2M_MODE);
                let fcAndO2Ids = this.getUniqueValuesByFieldName(fcAndO2List, 'option_id');

                return fcAndO2Ids.includes(option.id);
            },
            isSelectedOption2(option) {
                return this.$store.getters.selectedOptions2List.includes(option.id);
            },
            canUnselectOption2(option2Id) {
                let option = this.option2List.find(o2 => o2.id === option2Id);
                return !this.isDefaultOption2(option);
            },
            selectOption2(value) {
                if (value === BORE_CONTROLLER_OPTION2_ID) {
                    this.$store.dispatch('setRemoteControl', null);
                }
                this.$store.dispatch('selectOption2', value);
            },
            unselectOption2(value) {
                if (this.canUnselectOption2(value)) {
                    this.$store.dispatch('unselectOption2', value);
                }
            },
        },
        watch: {
            '$store.getters.selectedOptions1List': function() {
                this.showOptionsCombinationNotExistError = false;
            },
            '$store.getters.selectedOptions2List': function() {
                this.showOptionsCombinationNotExistError = false;
            },

            option1List: {
                handler(options) {
                    if (!options || this.$store.getters.pageNumber >= PAGE_FOURTH) {
                        return;
                    }
                    for (const option of options) {
                        if (this.isDefaultOption1(option)) {
                            this.selectOption1(option.id);
                        }
                    }
                },
                immediate: true
            },
            option2List: {
                handler(options) {
                    if (!options || this.$store.getters.pageNumber >= PAGE_FOURTH) {
                        return;
                    }
                    for (const option of options) {
                        if (this.isDefaultOption2(option)) {
                            this.selectOption2(option.id);
                        }
                    }
                },
                immediate: true
            }
        },
        computed: {
            errorText() {
                if (this.showOptionsCombinationNotExistError) {
                    return 'error.options_combination_does_not_exists';
                }
               return '';
            },
            line() {
                return this.getLine();
            },
            inputOption1List() {
                let o1List = this.getOption1List();
                if (!o1List) {
                    return null;
                }
                return o1List.filter(o1 => o1.mode === INPUT_MODE);
            },
            outputOption1List() {
                let o1List = this.getOption1List();
                if (!o1List) {
                    return null;
                }
                return o1List.filter(o1 => o1.mode === OUTPUT_MODE);
            },
            localOption2List() {
                let o2List = this.getOption2List();
                if (!o2List) {
                    return null;
                }
                return o2List.filter(o2 => o2.id !== ISOLATION_CONTROL_OPTION2_ID);
            }
        },
        created() {
            if (this.$store.getters.pageNumber < PAGE_THIRD) {
                this.$router.push({name: `page${this.$store.getters.pageNumber + 1}`});
            }

            this.$store.dispatch('setOption1Display', true);
            this.$store.dispatch('setOption2Display', true);
        },
        beforeUpdate() {
            this.$store.dispatch('setPageNumber', PAGE_THIRD);
            if (!this.disabledUpdateData) {
                this.resetDataFromNextPages();
            }
            this.disabledUpdateData = false;
        }
    }
</script>

<style scoped>

</style>
