<template>
    <div v-if="currentFC" class="сomponent-wrapper">
        <triol-header/>
        <triol-link/>
        <div class="main">
            <section class="main_content main_content__800">
                <h2 class="main_headline">
                    {{ $t('general.contents_of_delivery') }}
                </h2>

                <h3 class="main_headline">{{ $t('general.frequency_converter') }}</h3>
                <ol class="result_list">
                    <li class="result_list-item">
                        <div>{{ nomenclatureFC }}</div>
                        <div class="result_list-content">
                            <p>{{ $t('general.power') }}: {{ powerLabel }}</p>
                            <p>{{ $t('general.network_voltage_simple') }}: {{ voltageValue }} {{ $t('measures.voltage.volt') }}</p>
                            <p>{{ $t('general.degree_of_protection_case') }}: {{ degreeOfProtectionValue }}</p>
                            <p v-for="option in internalOptions1Info" :key="'internal-o1' + option.id">
                                {{ $t(option.name) }}
                            </p>
                            <p v-for="option in internalOptions2Info" :key="'internal-o2' + option.id">
                                {{ getInternalOption2Name(option) }}
                            </p>
                        </div>
                    </li>
                </ol>
                <h3 class="main_headline" v-if="showOptionsBlock">{{ $t('general.options') }}</h3>
                <ol>
                    <li class="result_list-item" v-for="index in selectedAIO2Count" :key="'aio2-' + index">
                        {{ $t('general.expansion_block') }} {{ blockAIO2Info }}
                    </li>
                     <li class="result_list-item" v-for="index in selectedExt24DinCount" :key="'ext24din-' + index">
                        {{ $t('general.expansion_block') }} {{ blockExt24DinInfo }}
                    </li>

                    <li class="result_list-item" v-if="selectedInterface">
                        {{ $t('general.expansion_block') }} {{ blockInterfaceInfo }}
                    </li>
                    <li class="result_list-item" v-if="selectedEncoder">
                        {{ $t('general.expansion_block') }} {{ blockEncoderInfo }}
                    </li>
                    <li class="result_list-item" v-if="remoteControlObject">
                        {{ $t('general.remote_control') }} {{ $t(remoteControlObject.name)}}
                    </li>
                    <li class="result_list-item" v-for="option in externalOptions1Info" :key="'external-o1' + option.id">
                        <p>{{ getOption1FullNomenclature(option) }}</p>
                    </li>
                    <li class="result_list-item" v-for="option in externalOptions2Info" :key="'external-o2-' + option.id">
                        <p>{{ getOption2FullNomenclature(option) }}</p>
                    </li>
                </ol>
                <form>
                    <div class="result_btn-wrapper">
                        <button class="result_btn" type="button" @click="downloadPdf">{{ $t('general.save') }}</button>
                        <button class="result_btn" type="button" @click="showEmailForm = true">
                            {{ $t('general.send_to_mail') }}
                        </button>
                    </div>
                    <div v-show="showSuccessSendingToEmail" class="result_report-sent">{{ $t('send_to_email.success') }}</div>
                    <div v-show="showErrorSendingToEmail" class="result_error">{{ $t('send_to_email.error') }}</div>
                    <div class="result_box-input" v-show="showEmailForm">
                        <div class="result_input-wrapper">
                            <label class="result_label" for="e-mail">{{ $t('general.mail') }}<span>*</span></label>
                            <input class="result_input" v-model="email" type="text" id="e-mail"
                                   :class="{'result_input__error': $v.email.$error}">
                        </div>
                        <div class="result_input-wrapper">
                            <label class="result_label" for="organization">{{ $t('general.organization') }}</label>
                            <input class="result_input" v-model="organization" type="text" id="organization">
                        </div>
                        <div class="result_input-wrapper">
                            <label class="result_label" for="name">{{ $t('general.name') }}</label>
                            <input class="result_input" v-model="name" type="text" id="name">
                        </div>
                        <div class="result_input-wrapper">
                            <label class="result_label" for="surname">{{ $t('general.surname') }}</label>
                            <input class="result_input" v-model="surname" type="text" id="surname">
                        </div>
                    </div>
                    <button class="result_btn result_btn__center" @click.prevent="sendToEmail" v-show="showEmailForm">
                        {{ $t('general.send') }}
                    </button>
                </form>
            </section>
            <restart/>
            <div class="block-hidden"></div>
        </div>
        <progress-navigator @previous="previous" @next="next" :current-page="currentPage" class-disabled='footer_disabled'/>
    </div>
</template>

<script>
    import Restart from "../Restart";
    import {PAGE_SEVENTH, PAGE_SIXTH} from "../../constants/page-constants";
    import ProgressNavigator from "../ProgressNavigator";
    import NomenclatureMixin from "../../mixins/NomenclatureMixin";
    import {mapGetters} from "vuex";
    import {AIO2_ID, EXT24_DIN_ID} from "../../constants/expand-blocks-constants";
    import TriolHeader from "../TriolHeader";
    import {powerFromLabelToNumber} from "../../utils/utils";
    import {O1_EXTERNAL_M2M_MODE} from "../../constants/option1-constants";
    import {O2_EXTERNAL_M2M_MODE, SHIELD_OUTLET_OPTION2_ID} from "../../constants/option2-constants";
    import {email, required} from "vuelidate/lib/validators";
    import {BACKEND_API} from "../../axios-config";
    import download from 'downloadjs';
    import {VOLTAGE_VALUE_MAP} from "../../constants/voltage-constants";
    import TriolLink from "../TriolLink";
    import {config} from "../../config";

    export default {
        name: "ResultPage",
        components: {TriolHeader, ProgressNavigator, Restart, TriolLink},
        mixins: [NomenclatureMixin],
        validations() {
            return {
                email: {email, required}
            }
        },
        data() {
            return {
                currentFC: null,

                currentPage: PAGE_SEVENTH,

                showEmailForm: false,
                showSuccessSendingToEmail: false,
                showErrorSendingToEmail: false,

                email: '',
                organization: '',
                name: '',
                surname: '',
            }
        },
        computed: {
            showOptionsBlock() {
                return this.selectedAIO2Count || this.selectedExt24DinCount || this.selectedInterface
                    || this.selectedEncoder || this.remoteControlObject || this.externalOptions1Info.length
                    || this.externalOptions2Info.length;
            },
            blockAIO2Info() {
                return this.getExpandBlockName(this.expandBlocksList.find(block => block.id === AIO2_ID));
            },
            blockExt24DinInfo() {
                return this.getExpandBlockName(this.expandBlocksList.find(block => block.id === EXT24_DIN_ID));
            },
            blockInterfaceInfo() {
                return this.getExpandBlockName(this.expandBlocksList.find(block => block.id === this.selectedInterface));
            },
            blockEncoderInfo() {
                return this.getExpandBlockName(this.expandBlocksList.find(block => block.id === this.selectedEncoder));
            },
            internalOptions1Info() {
                return this.selectedOptions1Objects.filter(option => {
                    let optionFilter = m2mOption => {
                        return m2mOption.frequency_converter_id === this.currentFC.id
                                && m2mOption.voltage_id === this.voltage
                                && m2mOption.option_id === option.id
                    };
                    return this.fcAndOption1List.find(optionFilter).mode !== O1_EXTERNAL_M2M_MODE;
                });
            },
            externalOptions1Info() {
                return this.selectedOptions1Objects.filter(option => {
                    let optionFilter = m2mOption => {
                        return m2mOption.frequency_converter_id === this.currentFC.id
                                && m2mOption.voltage_id === this.voltage
                                && m2mOption.option_id === option.id
                    };
                    return this.fcAndOption1List.find(optionFilter).mode === O1_EXTERNAL_M2M_MODE;
                });
            },
            internalOptions2Info() {
                return this.selectedOptions2Objects.filter(option => {
                    let optionFilter = m2mOption => {
                        return m2mOption.frequency_converter_id === this.currentFC.id
                                && m2mOption.option_id === option.id
                    };
                    return this.fcAndOption2List.find(optionFilter).mode !== O2_EXTERNAL_M2M_MODE;
                });
            },
            externalOptions2Info() {

                return this.selectedOptions2Objects.map(option => {
                    let optionFilter = m2mOption => {
                        return m2mOption.frequency_converter_id === this.currentFC.id
                                && m2mOption.option_id === option.id
                    };
                    option.nomenclature = this.fcAndOption2List.find(optionFilter).nomenclature;
                    return option;
                }).filter(option => {
                    let optionFilter = m2mOption => {
                        return m2mOption.frequency_converter_id === this.currentFC.id
                                && m2mOption.option_id === option.id
                    };
                    return this.fcAndOption2List.find(optionFilter).mode === O2_EXTERNAL_M2M_MODE;
                });
            },

            powerLabel() {
                return `${powerFromLabelToNumber(this.humanizedPower)} ${this.$t('measures.power.kilowatt')}`;
            },

            ...mapGetters([
                'remoteControlObject',

                'expandBlocksList',
                'selectedEncoder',
                'selectedInterface',
                'selectedAIO2Count',
                'selectedExt24DinCount',

                'humanizedPower',
                'voltageValue',
                'voltage',
                'degreeOfProtectionValue',
                'modeOfOpsText',

                'selectedOptions1Objects',
                'selectedOptions2Objects',

                'userFlow', 'userPower', 'efficiency', 'cos', 'modeOfOps', 'userUpperBoundTemp',
                'userLowerBoundTemp', 'userAltitude',
                'placement', 'degreeOfProtection', 'montageType', 'selectedOptions1List', 'selectedOptions2List',
                'selectedRemoteControl', 'toolMode', 'userPowerMeasure', 'altitudeMeasure', 'tempMeasure'
            ]),
        },
        methods: {
            getInternalOption2Name(option) {
                if (option.id === SHIELD_OUTLET_OPTION2_ID) {
                    return this.$t('option2.dimmer_room_outlet.nomenclature');
                }
                return this.$t(option.name);
            },
            getOption1FullNomenclature(option) {
                return `${this.$t(option.name)} ${this.currentFC.nomenclature}-${option.label}-${this.getHumanizedPower()}-${VOLTAGE_VALUE_MAP[this.voltageValue]} - ${this.$t('general.external_installation')}`;
            },
            getOption2FullNomenclature(option) {
                return `${this.$t(option.name)} ${this.$t(option.nomenclature)} - ${this.$t('general.external_installation')}`;
            },
            previous() {
                this.$router.push({name: `page${PAGE_SIXTH}`});
            },
            next() {
                // TODO: add logic
            },

            async downloadPdf() {
                const pdfConfig = {
                    responseType: 'blob',
                    params: this.getPdfParameters(),
                };
                await BACKEND_API.get(`service/details_pdf`, pdfConfig).then((response) => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});

                    const now = new Date();
                    const nowString = `${now.getFullYear()}-${
                        ("0" + (now.getMonth() + 1)).slice(-2)
                    }-${now.getDate()} ${now.getHours()}-${now.getMinutes()}`;

                    const lang = config.PDF_LANGUAGE_MAP[this.$route.params.lang] || this.$route.params.lang;
                    download(blob, `${this.nomenclatureFC} - ${nowString} (${lang.toUpperCase()}).pdf`)
                });
            },
            async sendToEmail() {
                if (this.$v.$invalid) {
                    this.$v.$touch();
                    return;
                }
                let data = this.getPdfParameters();
                data['recipient'] = this.email;
                data['organization'] = this.organization;
                data['first_name'] = this.name;
                data['last_name'] = this.surname;

                await BACKEND_API.post(`service/send_pdf_to_email`, data).then(() => {
                    setTimeout(() => this.showSuccessSendingToEmail = false, 5000);
                    this.showSuccessSendingToEmail = true;
                }).catch(() => {
                    setTimeout(() => this.showErrorSendingToEmail = false, 5000);
                    this.showErrorSendingToEmail = true;
                });
            },

            getExpandBlockName(block) {
                return block.alternative_name || block.name;
            },
            getPdfParameters() {
                let params = {
                    'lang': this.$route.params.lang,
                    'timezone_offset': Number(new Date().getTimezoneOffset() / -60),

                    'voltage': this.voltageValue,
                    'flow': this.userFlow.replace(',', '.'),
                    'power': this.userPower.replace(',', '.'),
                    'efficiency': this.efficiency,
                    'cos': this.cos,
                    'power_measure': this.userPowerMeasure,

                    'mode_of_ops': this.modeOfOps || this.toolMode,
                    'upper_bound_temp': this.userUpperBoundTemp,
                    'lower_bound_temp': this.userLowerBoundTemp,
                    'altitude': this.userAltitude,
                    'temp_measure': this.tempMeasure,
                    'altitude_measure': this.altitudeMeasure,

                    'selected_aio2_count': this.selectedAIO2Count,
                    'selected_ext24_din_count': this.selectedExt24DinCount,
                    'selected_encoder': this.selectedEncoder,
                    'selected_interface': this.selectedInterface,

                    'placement': this.placement,
                    'degree_of_protection_id': this.degreeOfProtection,
                    'montage_type': this.montageType,

                    'selected_options1_list': this.selectedOptions1List,
                    'selected_options2_list': this.selectedOptions2List,
                    'selected_remote_control': this.selectedRemoteControl,

                    'fc_id': this.currentFC.id,
                    'nomenclature': this.currentFC.nomenclature,
                    'power_label': this.currentFC.power,
                    'line': this.currentFC.line,
                    'option1_label': this.getOption1(),
                    'option2_label': this.getOption2(),
                };
                return Object.fromEntries(Object.entries(params).filter(data => !['', null].includes(data[1])));
            },
        },
        created() {
            if (this.$store.getters.pageNumber < PAGE_SIXTH) {
                this.$router.push({name: `page${this.$store.getters.pageNumber + 1}`});
            }

            this.currentFC = this.getFC();
        }
    }
</script>

<style scoped>

</style>
