import {BACKEND_API} from "../axios-config";

export default {
    state: {
        // list of objects in the format [{value: String, text: String}, ...].
        // e.g. [{value: 'general', text: 'Общепромышленное'},
        //       {value: 'petrol', text: 'Нефтедобывающая промышленность'}]
        implementationsList: null,
        implementation: null,  // selected value ('value' key)

        // list of objects in the format [
        //  {id: Number, name: String, implementation: String, mode: String, overload: Number, time: Number},
        //  ...
        // ].
        // e.g. [{id: 1, name: 'Вентилятор', implementation: 'general', mode: 'normal', overload: 120, time: 60}, ...]
        toolsList: null,
        tool: null,  // selected value ('id' key)
        toolMode: null,

        // list of objects in the format [{value: String, text: String}, ...].
        // e.g. [{value: 'normal', text: 'Нормальный'},
        //       {value: 'hard', text: 'Тяжелый'}]
        modesOfOpsList: null,
        modeOfOps: null,  // selected value ('value' key)
    },
    getters: {
        implementationsList: state => state.implementationsList,
        implementation: state => state.implementation,

        toolsList: state => state.toolsList,
        tool: state => state.tool,
        toolMode: state => state.toolMode,

        modesOfOpsList: state => state.modesOfOpsList,
        modeOfOps: state => state.modeOfOps,

        modeOfOpsText: state => state.modesOfOpsList.find(mode => [state.modeOfOps, state.toolMode].includes(mode.value)).text,
    },
    mutations: {
        setImplementation(state, payload) {
            state.implementation = payload;
        },
        setImplementationsList(state, payload) {
            state.implementationsList = payload;
        },

        setTool(state, payload) {
            state.tool = payload;
        },
        setToolsList(state, payload) {
            state.toolsList = payload;
        },
        setToolMode(state, payload) {
            state.toolMode = payload;
        },

        setModeOfOps(state, payload) {
            state.modeOfOps = payload;
        },
        setModesOfOpsList(state, payload) {
            state.modesOfOpsList = payload;
        },
    },
    actions: {
        setImplementation({commit}, value) {
            commit('setImplementation', value);
        },
        async loadImplementationsList({commit}) {
            const response = await BACKEND_API.get('implementations/');
            commit('setImplementationsList', response.data);
        },

        setTool({commit}, value) {
            commit('setTool', value);
        },
        async loadToolsList({commit}) {
            const response = await BACKEND_API.get('tools/');
            commit('setToolsList', response.data);
        },
        setToolMode({commit}, value) {
            commit('setToolMode', value);
        },

        setModeOfOps({commit}, value) {
            commit('setModeOfOps', value);
        },
        async loadModesOfOpsList({commit}) {
            const response = await BACKEND_API.get('modes_of_operations/');
            commit('setModesOfOpsList', response.data);
        },
    }
}
