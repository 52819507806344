<template>
    <div class="checkbox_wrapper checkbox_wrapper__column">
        <div class="checkbox_box" :class="boxClass" v-for="(option, index) in options" :key="index">
            <label class="checkbox" :class="[checkboxClass, {'checkbox__active': selected === option.id},
                                             {'checkbox__disabled': option.disabled}]">
                <input class="checkbox_radio" type="radio" :value="option.id" v-model="selected"
                       :id="'remote-control-select-' + uniqueElementKey + '-' + index"
                       :disabled="selected === option.id || option.disabled"
                       @click="uncheckIfAlreadySelected">
                <label class="checkbox_label" :for="'remote-control-select-' + uniqueElementKey + '-' + index">
                    {{ getOptionName(option) }}
                </label>
            </label>
            <template v-if="option.info_text">
                <div class="main_info main_info__pb-15">
                    <img class="main_info-image" :src="imageInfo" alt="Info" width="20px" height="20px">
                    <span class="main_info-text" v-html="$t(option.info_text).replaceAll('\n', '<br>')"/>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RemoteControlRadioSelect",
        props: {
            value: null,

            options: Array,
            checkboxClass: String,
            boxClass: String,

            uniqueElementKey: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                selected: null,
                imageInfo: require('@/assets/image/info_24px.svg'),
            }
        },
        methods: {
            uncheckIfAlreadySelected(event) {
                if (event.target.value === this.selected
                        || typeof this.selected === 'number' && parseInt(event.target.value) === this.selected
                        || typeof this.selected === 'boolean' && event.target.value === "true" && this.selected
                ) {
                    this.selected = null;
                }
            },
            getOptionName(option) {
                return option.prefix ? this.$t(option.prefix) + ' ' + this.$t(option.name) : this.$t(option.name);
            }
        },
        watch: {
            selected: function (newVal) {
                this.$emit('input', newVal)
            },
            value: {
                handler(newVal) {
                    this.selected = newVal
                },
                immediate: true,
            }
        }
    }
</script>

<style scoped>

</style>
