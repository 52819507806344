<template>
    <div class="remote-control_wrapper">
        <div class="remote-control" :class="class_" alt="Remote Control"></div>
    </div>
</template>

<script>
    export default {
        name: "RemoteControl",
        props: {
            class_: {
                type: String,
                default: "",
            }
        }
    }
</script>

<style scoped>

</style>