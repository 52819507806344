import {O1_EXTERNAL_M2M_MODE, O1_FILTERS_VALUES_MAP} from "../constants/option1-constants";
import {eqSet, intersectionSet} from "../utils/utils";
import {
    IP54_OPTION2_VALUE,
    O2_EXTERNAL_M2M_MODE,
    O2_FILTERS_VALUES_MAP, SHIELD_OUTLET_OPTION2_ID
} from "../constants/option2-constants";
import {IP54_DEGREE_OF_PROTECTION_ID} from "../constants/conditions-constants";

export default {
    name: "NomenclatureMixin",
    computed: {
        nomenclatureFC() {
            const suffix = [this.getLine(), this.getOption1(), this.getOption2()].join('');
            return [this.getNomenclature(), this.getHumanizedPower(), this.getVoltageChar(), suffix].join('-');
        },
    },
    methods: {
        filterSelectedOptions1(set) {
            let fcAndO1List = this.getFcAndO1ListByFCAndVoltage().filter(o1 => o1.mode !== O1_EXTERNAL_M2M_MODE);
            let fcAndO1Ids = this.getUniqueValuesByFieldName(fcAndO1List, 'option_id');

            let nonExternalOption1Values = this.option1List.filter(o1 => fcAndO1Ids.includes(o1.id)).map(o1 => o1.value);
            let mappedSet = new Set(this.option1List.filter(o1 => set.has(o1.id)).map(o1 => o1.value));
            return intersectionSet(mappedSet, new Set(nonExternalOption1Values));
        },
        filterSelectedOptions2(set) {
            let fcAndO2List = this.getFcAndO2ListByFC().filter(o2 => o2.mode !== O2_EXTERNAL_M2M_MODE);
            let fcAndO2Ids = this.getUniqueValuesByFieldName(fcAndO2List, 'option_id');

            let nonExternalOption2Values = new Set(this.option2List.filter(o2 => fcAndO2Ids.includes(o2.id)).map(o2 => o2.value));
            let mappedSet = new Set(this.option2List.filter(o2 => set.has(o2.id)).map(o2 => o2.value));

            let result = intersectionSet(mappedSet, nonExternalOption2Values);

            // Special case
            if (this.$store.getters.degreeOfProtection === IP54_DEGREE_OF_PROTECTION_ID) {
                result.add(IP54_OPTION2_VALUE);
            }
            return result
        },

        getHumanizedPower() {
            return this.$store.getters.humanizedPower || '___';
        },
        getOption1() {
            if (!this.$store.getters.option1Display) {
                return '_'
            }
            let selectedOptions = new Set(this.$store.getters.selectedOptions1List);
            selectedOptions = this.filterSelectedOptions1(selectedOptions);

            for (const [value, set] of Object.entries(O1_FILTERS_VALUES_MAP)) {
                if (eqSet(selectedOptions, set)) {
                    return value;
                }
            }
            return '_';
        },
        getOption2() {
            if (!this.$store.getters.option2Display) {
                return '_'
            }
            let selectedOptions = new Set(this.$store.getters.selectedOptions2List);
            
            // special case
            selectedOptions.delete(SHIELD_OUTLET_OPTION2_ID);
            
            selectedOptions = this.filterSelectedOptions2(selectedOptions);

            for (const [value, set] of Object.entries(O2_FILTERS_VALUES_MAP)) {
                if (eqSet(selectedOptions, set)) {
                    return value;
                }
            }
            return '_';
        }
    },
}
