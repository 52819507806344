<template>
    <section class="start_wrapper">
        <triol-header class="header__absolute"/>
        <div class="start">
            <figure class="start_logo">
                <img class="start_logo-img" :src="imagesLangUrl" alt="logo">
            </figure>
            <div class="start_content">
                <span>TriolSelect</span><br>
                <span>{{ $t('general.low_voltage') }}</span>
            </div>
            <button class="start_btn" type="button" @click="next">{{ $t('general.start_configuration') }}</button>
            <img class="start_gears" src="@/assets/image/iconfinder_System Preferences_37063.svg" alt="gears">
        </div>
    </section>
</template>

<script>
    import {PAGE_FIRST, PAGE_ZERO} from "../../constants/page-constants";
    import TriolHeader from "../TriolHeader";
    import {IMAGE_LANG} from "../../constants/constants";

    export default {
        name: "Page0",
        components: {TriolHeader},
        methods: {
            next() {
                this.$store.dispatch('setPageNumber', PAGE_ZERO);
                this.$router.push({name: `page${PAGE_FIRST}`});
            },
        },
        computed: {
            imagesLangUrl() {
                return IMAGE_LANG[this.$route.params.lang] || '#';
            },
        }
    }
</script>

<style scoped>

</style>
