<template>
    <div class="сomponent-wrapper">
        <triol-header/>
        <triol-link/>
        <form class="main">
            <section class="main_content main_content__flex">
                <div class="main_content-half">
                    <h2 class="main_headline">{{ $t('general.implementation') }}</h2>
                    <div class="select_component-wrapper">
                        <common-select v-model="implementation" :options="implementationsList"/>
                    </div>
                    <div class="select_component-wrapper"  v-if="implementation">
                        <common-select v-model="tool" :options="toolsList" option-key-name="id" option-text-name="name"
                                    with-empty/>
                    </div>
                </div>
                <div class="main_content-half">
                    <h2 class="main_headline">{{ $t('general.mode_of_operations') }}</h2>
                    <radio-select checkbox-class="checkbox__flex" :class="{ 'checkbox_wrapper__error': $v.modeOfOps.$error}"
                                  box-class="checkbox_box__img" :disabled="disabledModeOfOpsSelect"
                                  v-model="modeOfOps" :options="modesOfOpsList" :show-info-button="true" info-box-class="info-box__down"/>
                </div>
            </section>
            <p class="main_text">{{ $t('info.select_implementation_or_mode_of_operations') }}</p>
            <div class="main_border"></div>
            <section class="main_content">
                <h2 class="main_headline">{{ $t('general.operating_conditions') }}</h2>
                <div class="main_content__flex">
                    <div class="main_content-half">
                        <p class="main_text">{{ $t('general.temperature_exploitation') }}</p>
                        <div class="engine_wrapper">
                            <div class="engine engine__center">
                                <label class="engine_parameters" for="upper_limit">{{ $t('general.temp_upper_bound') }}</label>
                                <div class="engine_input-wrapper">
                                    <input  class="engine_input" v-model="userUpperBoundTemp"
                                            :class="{ 'engine_input__error': $v.userUpperBoundTemp.$error || showTemperatureError}"
                                            :placeholder="$t('general.no_selected')" id="upper_limit"
                                            v-maska="{mask: 'M#*', tokens: {'M': {pattern: /[0-9\-]/}}}">
                                </div>
                                <common-select select-class="select_min engine_third-element" v-model="selectedTempMeasure" :options="tempMeasures"/>
                            </div>
                            <div class="engine engine__center">
                                <label class="engine_parameters" for="lower_limit">{{ $t('general.temp_lower_bound') }}</label>
                                <div class="engine_input-wrapper">
                                    <input class="engine_input" v-model="userLowerBoundTemp"
                                           :class="{ 'engine_input__error': $v.userLowerBoundTemp.$error || showTemperatureError}"
                                           :placeholder="$t('general.no_selected')" id="lower_limit"
                                           v-maska="{mask: 'M#*', tokens: {'M': {pattern: /[0-9\-]/}}}">
                                    <span class="engine_input-decryption">{{ tempRangeDisplay }}</span>
                                </div>
                                <div class="engine_third-element"></div>
                            </div>
                        </div>
                    </div>
                    <div class="main_content-half__right">
                        <div class="engine engine__center">
                            <label class="engine_parameters" for="height_above_sea_level">{{ $t('general.height_above_sea_level') }}</label>
                            <div class="engine_input-wrapper">
                                <input class="engine_input" v-model="userAltitude" :class="{ 'engine_input__error': $v.userAltitude.$error}"
                                    :placeholder="$t('general.no_selected')" id="height_above_sea_level"
                                    v-maska="'#####'">
                                <span class="engine_input-decryption">{{ altitudeMinValue }}...{{ altitudeMaxValue }}</span>
                            </div>
                            <common-select select-class="select_min" v-model="selectedAltitudeMeasure" :options="altitudeMeasures"/>
                        </div>
                        <div v-if="showConductiveDust">
                            <div class="checkbox_wrapper checkbox_wrapper__center">
                                <checkbox :option="conductiveDustOptions" checkbox-class="checkbox__flex"
                                          @checked="conductiveDust = true" @unchecked="conductiveDust = false"
                                          :checked="conductiveDust" :uniqueElementKey="'conductive-dust'"
                                          :disabled="disabledConductiveDust"
                                />
                            </div>
                            <div class="main_info">
                                <img class="main_info-image" src="@/assets/image/info_24px.svg" alt="" width="20px" height="20px">
                                <p class="main_info-text">{{ $t('info.dont_use_in_aggressive_environment') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <nomenclature/>
            <div class="block-hidden"></div>
        </form>
        <progress-navigator @previous="previous" @next="submit" :current-page="currentPage" :error-text="errorText"/>
    </div>
</template>

<script>
    import ProgressNavigator from "../ProgressNavigator";
    import CommonSelect from "../CommonSelect";
    import Nomenclature from "../Nomenclature";
    import RadioSelect from "../RadioSelect";
    import TriolHeader from "../TriolHeader";
    import {required, integer, between, minValue} from 'vuelidate/lib/validators'
    import {PAGE_FIRST, PAGE_SECOND, PAGE_THIRD} from "../../constants/page-constants";
    import {fromCtoF, fromFtoC} from "../../utils/utils";
    import Checkbox from "../Checkbox";
    import {DEFAULT_LOWER_BOUND_TEMP, DEFAULT_UPPER_BOUND_TEMP} from "../../constants/operation-constants";
    import LocaleWatchMixin from "../../mixins/LocaleWatchMixin";
    import ErrorScrollMixin from "../../mixins/ErrorScrollMixin";
    import TriolLink from "../TriolLink";

    export default {
        name: "Page2",
        components: {TriolHeader, Checkbox, RadioSelect, Nomenclature, CommonSelect, ProgressNavigator, TriolLink},
        mixins: [LocaleWatchMixin, ErrorScrollMixin],
        validations() {
            let fcList = this.getFCList();
            if (!fcList) {
                return {
                    modeOfOps: {},
                    userLowerBoundTemp: {},
                    userUpperBoundTemp: {},
                    userAltitude: {},
                };
            }

            return {
                modeOfOps: {required},

                userLowerBoundTemp: {required, integer, between: between(this.tempMinValue, this.tempMaxValue)},
                userUpperBoundTemp: {
                    required, integer, between: between(this.tempMinValue, this.tempMaxValue),
                    minValue: minValue(this.userLowerBoundTemp)
                },
                userAltitude: {required, integer, between: between(this.altitudeMinValue, this.altitudeMaxValue)},
            };
        },
        methods: {
            resetDataFromNextPages(savePage5 = false, savePage6 = false) {
                this.$store.dispatch('resetAfterPage2', {savePage5: savePage5, savePage6: savePage6});
            },

            getSelectedTempMeasureObject() {
                return this.tempMeasures.find(temp => temp.value === this.selectedTempMeasure);
            },
            getUserTempForCalculate(value) {
                value = parseInt(value);
                return this.getSelectedTempMeasureObject().func(value);
            },
            getUserTempForValidation(value) {
                value = parseInt(value);
                return this.getSelectedTempMeasureObject().validationFunc(value);
            },

            getSelectedAltitudeMeasureObject() {
                return this.altitudeMeasures.find(altitude => altitude.value === this.selectedAltitudeMeasure);
            },
            getUserAltitudeForCalculate() {
                return parseInt(this.userAltitude) / this.getSelectedAltitudeMeasureObject().koef;
            },

            async submit() {
                if (this.$v.$invalid) {
                    // TODO: display errors
                    await this.$v.$touch();
                    this.errorScrollFC()
                    return;
                }

                if (this.$store.getters.pageNumber < PAGE_SECOND) {
                    this.$store.dispatch('setImplementation', this.implementation_);
                    this.$store.dispatch('setModeOfOps', this.mode_);
                    this.$store.dispatch('setTool', this.tool_);
                    this.$store.dispatch('setToolMode', this.toolMode);

                    this.$store.dispatch('setUserLowerBoundTemp', this.userLowerBoundTemp);
                    this.$store.dispatch('setUserUpperBoundTemp', this.userUpperBoundTemp);
                    this.$store.dispatch('setUserAltitude', this.userAltitude);

                    this.$store.dispatch('setAltitude', parseInt(this.getUserAltitudeForCalculate()));

                    this.$store.dispatch('setTempMeasure', this.selectedTempMeasure);
                    this.$store.dispatch('setAltitudeMeasure', this.selectedAltitudeMeasure);

                    this.$store.dispatch('setConductiveDust', this.conductiveDust);

                    await this.$store.dispatch(
                        'loadHumanizedPower',
                        {
                            lowerBoundTemp: parseInt(this.getUserTempForCalculate(this.userLowerBoundTemp)),
                            upperBoundTemp: parseInt(this.getUserTempForCalculate(this.userUpperBoundTemp))
                        }
                    );

                    if (!this.$store.getters.humanizedPower || this.getFCList(PAGE_SECOND).length === 0) {
                        if (this.getUserTempForCalculate(this.userLowerBoundTemp) < DEFAULT_LOWER_BOUND_TEMP
                            && this.getUserTempForCalculate(this.userUpperBoundTemp) > DEFAULT_UPPER_BOUND_TEMP) {
                            this.showTemperatureError = true;
                        } else {
                            this.showNotFoundError = true;
                        }

                        this.$store.dispatch('resetAfterPage1');
                        this.$store.dispatch('setPageNumber', PAGE_FIRST);
                        return;
                    }
                    this.$store.dispatch('setLowerBoundTemp', parseInt(this.getUserTempForCalculate(this.userLowerBoundTemp)));
                    this.$store.dispatch('setUpperBoundTemp', parseInt(this.getUserTempForCalculate(this.userUpperBoundTemp)));

                    this.$store.dispatch('setPageNumber', PAGE_SECOND);
                }

                this.$router.push({name: `page${PAGE_THIRD}`});
            },
            previous() {
                this.$router.push({name: `page${PAGE_FIRST}`});
            },
            filterToolsList(toolsList) {
                if (!toolsList) {
                    return toolsList;
                }

                return toolsList.filter((tool) => {
                    if (this.implementation !== tool.implementation)
                        return false;

                    return !(this.mode_ && this.mode_ !== tool.mode);
                });
            },

            recalculateToolModeByImplementation(implementation) {
                let tools = this.$store.getters.toolsList.filter(tool => tool.implementation === implementation);

                return tools.length !== 0 && tools.every(tool => tool.mode === tools[0].mode) ? tools[0].mode : null;
            },
        },
        data() {
            return {
                showTemperatureError: false,
                showNotFoundError: false,

                currentPage: PAGE_SECOND,

                implementation_: null,
                mode_: null,
                tool_: null,
                toolMode: null,

                userLowerBoundTemp: '',
                userUpperBoundTemp: '',
                userAltitude: '',

                conductiveDust: null,
                conductiveDustOptions: {text: 'general.conductive_dust', infoText: 'info_text.conductive_dust'},

                // TODO: move to the Back-End
                tempMeasures: [
                    {'value': 1, 'text': '℃', 'func': (value) => value, 'truncTo': 50, 'validationFunc': (value) => value},
                    {'value': 2, 'text': '℉', 'func': fromFtoC, 'truncTo': 122, 'validationFunc': fromCtoF}
                ],
                altitudeMeasures: [
                    {'value': 1, 'text': 'measures.altitude.metre', 'koef': 1, 'from': 0, 'to': 4000},
                    {'value': 2, 'text': 'measures.altitude.ft', 'koef': 3.28084, 'from': 0, 'to': 13123}
                ],

                selectedTempMeasure: null,
                selectedAltitudeMeasure: null,

                implementationLoaded: false,
            }
        },
        computed: {
            errorText() {
                if (this.showTemperatureError) {
                    return 'error.temperatures_values_are_incorrect';
                } else if (this.showNotFoundError) {
                    return 'error.not_found_appropriate_FC'
                }
                return '';
            },
            tempMaxValue() {
                let fcList = this.getFCList(PAGE_FIRST);
                if (!fcList) {
                    return;
                }
                fcList = this.simpleFilterListByFieldName(fcList, 'implementation', this.implementation);

                let tempMaxValue = this.getUserTempForValidation(
                    Math.max(...this.getUniqueValuesByFieldName(fcList, 'max_temp'))
                );
                let tempMeasure = this.getSelectedTempMeasureObject();

                // TODO: improve it later
                if (this.$store.getters.power && this.$store.getters.power > 400) {  // 400 - is 'M40'
                    tempMaxValue = Math.min(tempMaxValue, tempMeasure.truncTo);
                }
                return tempMaxValue;
            },
            tempMinValue() {
                let fcList = this.getFCList(PAGE_FIRST);
                if (!fcList) {
                    return;
                }
                fcList = this.simpleFilterListByFieldName(fcList, 'implementation', this.$store.getters.implementation);

                return this.getUserTempForValidation(
                    Math.min(...this.getUniqueValuesByFieldName(fcList, 'lower_bound_temp'))
                );
            },
            tempRangeDisplay() {
                const maxValue = this.tempMaxValue;
                const minValue = this.tempMinValue;

                if (!maxValue && maxValue !== 0 || !minValue && minValue !== 0) {
                    return;
                }
                const maxStrValue = maxValue > 0 ? `+${maxValue}` : maxValue;
                const minStrValue = minValue > 0 ? `+${minValue}` : minValue;

                return `${minStrValue}...${maxStrValue}`;
            },

            altitudeMaxValue() {
                let altitudeMeasure = this.getSelectedAltitudeMeasureObject();
                return altitudeMeasure.to;
            },
            altitudeMinValue() {
                let altitudeMeasure = this.getSelectedAltitudeMeasureObject();
                return altitudeMeasure.from;
            },

            showConductiveDust() {
                let power = this.$store.getters.power;
                return power === 0 ||  5.5 < power && power < 400; // TODO: Special case. Improve it
            },
            disabledConductiveDust() {
                let fcList = this.getFCList(PAGE_FIRST);
                fcList = this.simpleFilterListByFieldName(fcList, 'implementation', this.implementation);

                return !this.getUniqueValuesByFieldName(fcList, 'conductive_dust').includes(true);
            },

            implementationsList() {
                let fcList = this.getFCList(PAGE_FIRST);
                if (this.conductiveDust) {
                    fcList = this.simpleFilterListByFieldName(fcList, 'conductive_dust', this.conductiveDust);
                }
                let fcImplementations = this.getUniqueValuesByFieldName(fcList, 'implementation');

                let result = this.$store.getters.implementationsList.filter(implementation => fcImplementations.includes(implementation.value));
                if (this.mode_) {
                    let tools = this.$store.getters.toolsList.filter(tool => tool.mode === this.mode_).map(tool => tool.implementation);
                    result = result.filter(implementation => tools.includes(implementation.value));
                }

                return result;
            },
            implementation: {
                get() {
                    return this.implementation_;
                },
                async set(value) {
                    if (this.implementationLoaded) {
                        this.tool_ = null;
                        this.toolMode = this.recalculateToolModeByImplementation(value);
                    }
                    this.implementation_ = value;
                    this.implementationLoaded = true;
                }
            },

            toolsList: {
                get() {
                    return this.filterToolsList(this.$store.getters.toolsList);
                }
            },
            tool: {
                get() {
                    return this.tool_;
                },
                set(value) {
                    this.tool_ = value;
                    this.toolMode = value ? this.toolsList.find(tool => tool.id === value).mode : this.recalculateToolModeByImplementation(this.implementation);
                }
            },

            modesOfOpsList() {
                return this.$store.getters.modesOfOpsList;
            },
            disabledModeOfOpsSelect() {
                return !!this.toolMode;
            },

            modeOfOps: {
                get() {
                    return this.mode_ || this.toolMode;
                },
                set(value) {
                    if (!this.mode_ && value === this.toolMode)
                        return;

                    this.mode_ = value;
                }
            },
        },
        watch: {
            implementation(newValue, oldValue) {
                if (oldValue !== null) {
                    this.disabledUpdateData = true;
                    this.resetDataFromNextPages(false, false)
                }
            },
            tool(newValue) {
                this.disabledUpdateData = true;
                if (this.$store.getters.pageNumber >= PAGE_SECOND) {
                    this.$store.dispatch('setTool', newValue);
                }
            },
            userLowerBoundTemp() {
                this.showTemperatureError = false;
                // if (oldValue !== '') {
                //     this.disabledUpdateData = true;
                //     this.resetDataFromNextPages(true, true)
                // }
            },
            userUpperBoundTemp() {
                this.showTemperatureError = false;
                // if (oldValue !== '') {
                //     this.disabledUpdateData = true;
                //     this.resetDataFromNextPages(true, true)
                // }
            },
            // userAltitude(newValue, oldValue) {
            //     if (oldValue !== '') {
            //         this.disabledUpdateData = true;
            //         this.resetDataFromNextPages(true, true)
            //     }
            // },
            selectedTempMeasure(newValue, oldValue) {
                if (newValue && oldValue) {
                    // this.disabledUpdateData = true;

                    const newTempMeasureObject = this.tempMeasures.find(temp => temp.value === newValue);
                    const oldTempMeasureObject = this.tempMeasures.find(temp => temp.value === oldValue);

                    if (this.userLowerBoundTemp) {
                        this.userLowerBoundTemp = Math.round(oldTempMeasureObject.func(
                            newTempMeasureObject.validationFunc(parseInt(this.userLowerBoundTemp))
                        ));
                    }

                    if (this.userUpperBoundTemp) {
                        this.userUpperBoundTemp = Math.round(oldTempMeasureObject.func(
                            newTempMeasureObject.validationFunc(parseInt(this.userUpperBoundTemp))
                        ));
                    }
                    // this.resetDataFromNextPages(true, true)
                }
            },
            selectedAltitudeMeasure(newValue, oldValue) {
                if (newValue && oldValue && this.userAltitude) {
                    // this.disabledUpdateData = true;

                    const newAltitudeMeasureObject = this.altitudeMeasures.find(altitude => altitude.value === newValue);
                    const oldAltitudeMeasureObject = this.altitudeMeasures.find(altitude => altitude.value === oldValue);

                    this.userAltitude = Math.round(
                        parseInt(this.userAltitude) * newAltitudeMeasureObject.koef / oldAltitudeMeasureObject.koef
                    );
                    // this.resetDataFromNextPages(true, true)
                }
            }
        },
        created() {
            if (this.$store.getters.pageNumber < PAGE_FIRST) {
                this.$router.push({name: `page${this.$store.getters.pageNumber + 1}`});
            }

            this.mode_ = this.$store.getters.modeOfOps;
            this.tool_ = this.$store.getters.tool;

            this.userLowerBoundTemp = this.$store.getters.userLowerBoundTemp;
            this.userUpperBoundTemp = this.$store.getters.userUpperBoundTemp;
            this.userAltitude = this.$store.getters.userAltitude;

            this.conductiveDust = this.$store.getters.conductiveDust;

            this.selectedTempMeasure = this.$store.getters.tempMeasure;
            this.selectedAltitudeMeasure = this.$store.getters.altitudeMeasure;

            this.implementation_ = this.$store.getters.implementation
                || this.checkUniqueValueByFieldName('implementation', PAGE_FIRST)
                || this.implementationsList[0].value;
            this.toolMode = this.$store.getters.toolMode || this.recalculateToolModeByImplementation(this.implementation_);
        },
        beforeUpdate() {
            if (!this.disabledUpdateData) {
                this.resetDataFromNextPages(true, true);
            }
            this.disabledUpdateData = false;
        }
    }
</script>

<style scoped lang='scss'>

</style>
