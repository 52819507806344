import {BACKEND_API} from "../axios-config";

export default {
    state: {
        option2List: null,
        fcAndOption2List: null,

        selectedOptions2List: [], // set of values of 'id' field
        option2Display: false,
    },
    getters: {
        option2List: state => state.option2List,
        fcAndOption2List: state => state.fcAndOption2List,

        selectedOptions2List: state => state.selectedOptions2List,
        option2Display: state => state.option2Display,

        selectedOptions2Objects: state => state.option2List.filter(option => state.selectedOptions2List.includes(option.id)),
    },
    mutations: {
        setOption2List(state, payload) {
            state.option2List = payload;
        },
        setFCAndOption2List(state, payload) {
            state.fcAndOption2List = payload;
        },

        selectOption2(state, payload) {
            if (!state.selectedOptions2List.includes(payload)) {
                state.selectedOptions2List.push(payload);
                state.selectedOptions2List = [...state.selectedOptions2List]; // for reactivity
            }
        },
        unselectOption2(state, payload) {
            const index = state.selectedOptions2List.indexOf(payload);
            if (index > -1) {
                state.selectedOptions2List.splice(index, 1);
                state.selectedOptions2List = [...state.selectedOptions2List]; // for reactivity
            }
        },
        clearSelectedOption2Set(state) {
            state.selectedOptions2List = [];
        },

        setOption2Display(state, payload) {
            state.option2Display = payload;
        },
    },
    actions: {
        async loadOption2List({commit}) {
            const response = await BACKEND_API.get('option2_list/');
            commit('setOption2List', response.data);
        },

        async loadFCAndOption2List({commit}) {
            const response = await BACKEND_API.get('fc/fc_and_o2/');
            commit('setFCAndOption2List', response.data);
        },
        selectOption2({commit}, value) {
            commit('selectOption2', value);
        },
        unselectOption2({commit}, value) {
            commit('unselectOption2', value);
        },

        setOption2Display({commit}, value) {
            commit('setOption2Display', value);
        },
        clearSelectedOption2Set({commit}) {
            commit('clearSelectedOption2Set')
        }
    }
}
