import {
    DEFAULT_ALTITUDE_VALUE,
    DEFAULT_LOWER_BOUND_TEMP,
    DEFAULT_UPPER_BOUND_TEMP
} from "../constants/operation-constants";

export default {
    state: {
        userLowerBoundTemp: DEFAULT_LOWER_BOUND_TEMP,
        userUpperBoundTemp: DEFAULT_UPPER_BOUND_TEMP,
        userAltitude: DEFAULT_ALTITUDE_VALUE,

        lowerBoundTemp: '',
        upperBoundTemp: '',
        altitude: '',

        tempMeasure: 1,
        altitudeMeasure: 1,

        conductiveDust: null,
    },
    getters: {
        userLowerBoundTemp: state => state.userLowerBoundTemp,
        userUpperBoundTemp: state => state.userUpperBoundTemp,
        userAltitude: state => state.userAltitude,

        lowerBoundTemp: state => state.lowerBoundTemp,
        upperBoundTemp: state => state.upperBoundTemp,
        altitude: state => state.altitude,

        tempMeasure: state => state.tempMeasure,
        altitudeMeasure: state => state.altitudeMeasure,

        conductiveDust: state => state.conductiveDust,
    },
    mutations: {
        setUserLowerBoundTemp(state, payload) {
            state.userLowerBoundTemp = payload;
        },
        setUserUpperBoundTemp(state, payload) {
            state.userUpperBoundTemp = payload;
        },
        setUserAltitude(state, payload) {
            state.userAltitude = payload;
        },

        setLowerBoundTemp(state, payload) {
            state.lowerBoundTemp = payload;
        },
        setUpperBoundTemp(state, payload) {
            state.upperBoundTemp = payload;
        },
        setAltitude(state, payload) {
            state.altitude = payload;
        },

        setTempMeasure(state, payload) {
            state.tempMeasure = payload;
        },
        setAltitudeMeasure(state, payload) {
            state.altitudeMeasure = payload;
        },

        setConductiveDust(state, payload) {
            state.conductiveDust = payload;
        },
    },
    actions: {
        setUserLowerBoundTemp({commit}, value) {
            commit('setUserLowerBoundTemp', value);
        },
        setUserUpperBoundTemp({commit}, value) {
            commit('setUserUpperBoundTemp', value);
        },
        setUserAltitude({commit}, value) {
            commit('setUserAltitude', value);
        },

        setLowerBoundTemp({commit}, value) {
            commit('setLowerBoundTemp', value);
        },
        setUpperBoundTemp({commit}, value) {
            commit('setUpperBoundTemp', value);
        },
        setAltitude({commit}, value) {
            commit('setAltitude', value);
        },

        setTempMeasure({commit}, value) {
            commit('setTempMeasure', value);
        },
        setAltitudeMeasure({commit}, value) {
            commit('setAltitudeMeasure', value);
        },

        setConductiveDust({commit}, value) {
            commit('setConductiveDust', value);
        },
    }
}
