<template>
    <a href="https://triolcorp.eu/en" class="header_link-site">{{ $t('general.back_to_site')}}</a>
</template>

<script>
    export default {
        name: "TriolLink",
    }
</script>

<style scoped>

</style>
