import Router from "vue-router";
import {config} from '../config';
import Page0 from "../components/pages/Page0";
import Page1 from "../components/pages/Page1";
import Page2 from "../components/pages/Page2";
import Page3 from "../components/pages/Page3";
import Page4 from "../components/pages/Page4";
import Page5 from "../components/pages/Page5";
import Page6 from "../components/pages/Page6";
import ResultPage from "../components/pages/ResultPage";
import {
    PAGE_FIFTH,
    PAGE_FIRST,
    PAGE_FOURTH,
    PAGE_SECOND,
    PAGE_SEVENTH,
    PAGE_SIXTH,
    PAGE_THIRD,
    PAGE_ZERO
} from "../constants/page-constants";


export const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            beforeEnter: (to, from, next) => {
                next({
                    name: `page${PAGE_ZERO}`,
                    params: {
                        lang: from.params.lang || config.DEFAULT_LANGUAGE,
                    },
                })
            },
        },
        {
            path: '/:lang', name: 'startPage', redirect: `/:lang/page/${PAGE_ZERO}`
        },
        {
            path: `/:lang/page/${PAGE_ZERO}`,
            name: `page${PAGE_ZERO}`,
            component: Page0,
        },
        {
            path: `/:lang/page/${PAGE_FIRST}`,
            name: `page${PAGE_FIRST}`,
            component: Page1,
        },
        {
            path: `/:lang/page/${PAGE_SECOND}`,
            name: `page${PAGE_SECOND}`,
            component: Page2,
        },
        {
            path: `/:lang/page/${PAGE_THIRD}`,
            name: `page${PAGE_THIRD}`,
            component: Page3,
        },
        {
            path: `/:lang/page/${PAGE_FOURTH}`,
            name: `page${PAGE_FOURTH}`,
            component: Page4,
        },
        {
            path: `/:lang/page/${PAGE_FIFTH}`,
            name: `page${PAGE_FIFTH}`,
            component: Page5,
        },
        {
            path: `/:lang/page/${PAGE_SIXTH}`,
            name: `page${PAGE_SIXTH}`,
            component: Page6,
        },
        {
            path: `/:lang/page/${PAGE_SEVENTH}`,
            name: `page${PAGE_SEVENTH}`,
            component: ResultPage,
        },
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});


router.beforeEach((to, from, next) => {
    const lang = to.params.lang || from.params.lang || config.DEFAULT_LANGUAGE;

    if (!Object.keys(config.LANGUAGES).includes(lang)) {
        let newUrl = {
            name: to.name,
            params: {lang: config.DEFAULT_LANGUAGE},
        };
        next(newUrl);
    } else {
        next();
    }
});
