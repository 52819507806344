import {LE_LINE, RT_LINE, SD_LINE, SR_LINE, UC_LINE, UH_LINE} from "./constants";

export const DEFAULT_ENGINE_TYPE_VALUE = false;

// in kilowatt
export const FLOW_MIN_VALUE_BY_LINE_MAP = {};
FLOW_MIN_VALUE_BY_LINE_MAP[UC_LINE] = {380: 0.4, 480: 0.4};
FLOW_MIN_VALUE_BY_LINE_MAP[UH_LINE] = {380: 4, 480: 4};
FLOW_MIN_VALUE_BY_LINE_MAP[SD_LINE] = {380: 105, 480: 105, 690: 90};
FLOW_MIN_VALUE_BY_LINE_MAP[SR_LINE] = {380: 4, 480: 4};
FLOW_MIN_VALUE_BY_LINE_MAP[RT_LINE] = {380: 15, 480: 15};
FLOW_MIN_VALUE_BY_LINE_MAP[LE_LINE] = {380: 4, 480: 4};


export const FLOW_MAX_VALUE_BY_LINE_MAP = {};
FLOW_MAX_VALUE_BY_LINE_MAP[UC_LINE] = {380: 730, 480: 590};
FLOW_MAX_VALUE_BY_LINE_MAP[UH_LINE] = {380: 730, 480: 590};
FLOW_MAX_VALUE_BY_LINE_MAP[SD_LINE] = {380: 3200, 480: 2560, 690: 3200};
FLOW_MAX_VALUE_BY_LINE_MAP[SR_LINE] = {380: 145, 480: 124};
FLOW_MAX_VALUE_BY_LINE_MAP[RT_LINE] = {380: 400, 480: 302};
FLOW_MAX_VALUE_BY_LINE_MAP[LE_LINE] = {380: 60, 480: 52};


export const FLOW_SUPPLY_AS_A_PERCENTAGE = 2;

export const FLOW_MIN_VALUE_BY_VOLTAGE_MAP = {
    380: 0.4,
    480: 0.4,
    690: 90,
};

export const FLOW_MAX_VALUE_BY_VOLTAGE_MAP = {
    380: 3200,
    480: 2560,
    690: 3200,
};
