export const O2_DEFAULT_M2M_MODE = 'default';
export const O2_INTERNAL_M2M_MODE = 'internal';
export const O2_EXTERNAL_M2M_MODE = 'external';

export const BRAKE_KEY_OPTION2_VALUE = 1;
export const ADD_COVER_OPTION2_VALUE = 3;
export const BORE_CONTROLLER_OPTION2_VALUE = 4;
export const ISOLATION_CONTROL_OPTION2_VALUE = 5;
export const IP54_OPTION2_VALUE = 6;
export const BAYPASS_OPTION2_VALUE = 8;
export const BLOCK_RECUPERATION_OPTION2_VALUE = 1000;
export const SHIELD_OUTLET_OPTION2_VALUE = 1001;

export const BORE_CONTROLLER_OPTION2_ID = 3;
export const SHIELD_OUTLET_OPTION2_ID = 4;
export const ISOLATION_CONTROL_OPTION2_ID = 6;


export const O2_FILTERS_VALUES_MAP = {
    '0': new Set(),
    '1': new Set([BRAKE_KEY_OPTION2_VALUE]),
    // '2': new Set([2]),
    '3': new Set([ADD_COVER_OPTION2_VALUE]),
    '4': new Set([BORE_CONTROLLER_OPTION2_VALUE]),
    // '5': new Set([ISOLATION_CONTROL_OPTION2_VALUE]),
    '6': new Set([IP54_OPTION2_VALUE]),
    '8': new Set([BAYPASS_OPTION2_VALUE]),

    'A': new Set([BRAKE_KEY_OPTION2_VALUE, ADD_COVER_OPTION2_VALUE, BORE_CONTROLLER_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'B': new Set([BRAKE_KEY_OPTION2_VALUE, ADD_COVER_OPTION2_VALUE]),
    'C': new Set([BRAKE_KEY_OPTION2_VALUE, BORE_CONTROLLER_OPTION2_VALUE]),
    'D': new Set([BRAKE_KEY_OPTION2_VALUE, ADD_COVER_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'E': new Set([BRAKE_KEY_OPTION2_VALUE, IP54_OPTION2_VALUE]),
    'F': new Set([ADD_COVER_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'G': new Set([BRAKE_KEY_OPTION2_VALUE, BORE_CONTROLLER_OPTION2_VALUE, IP54_OPTION2_VALUE]),
    'H': new Set([ADD_COVER_OPTION2_VALUE, BORE_CONTROLLER_OPTION2_VALUE]),
    'I': new Set([ADD_COVER_OPTION2_VALUE, IP54_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'J': new Set([ADD_COVER_OPTION2_VALUE, IP54_OPTION2_VALUE]),
    'K': new Set([BORE_CONTROLLER_OPTION2_VALUE, IP54_OPTION2_VALUE]),
    'L': new Set([BRAKE_KEY_OPTION2_VALUE, IP54_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'M': new Set([BRAKE_KEY_OPTION2_VALUE, ADD_COVER_OPTION2_VALUE, BORE_CONTROLLER_OPTION2_VALUE]),
    'N': new Set([ADD_COVER_OPTION2_VALUE, BORE_CONTROLLER_OPTION2_VALUE, IP54_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'P': new Set([BRAKE_KEY_OPTION2_VALUE, ADD_COVER_OPTION2_VALUE, IP54_OPTION2_VALUE]),
    'Q': new Set([BORE_CONTROLLER_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'R': new Set([BRAKE_KEY_OPTION2_VALUE, ADD_COVER_OPTION2_VALUE, IP54_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'S': new Set([IP54_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'T': new Set([BRAKE_KEY_OPTION2_VALUE, ADD_COVER_OPTION2_VALUE, BORE_CONTROLLER_OPTION2_VALUE, IP54_OPTION2_VALUE]),
    'U': new Set([ADD_COVER_OPTION2_VALUE, BORE_CONTROLLER_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'V': new Set([BORE_CONTROLLER_OPTION2_VALUE, IP54_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'W': new Set([BRAKE_KEY_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'X': new Set([BRAKE_KEY_OPTION2_VALUE, BORE_CONTROLLER_OPTION2_VALUE, IP54_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'Y': new Set([BRAKE_KEY_OPTION2_VALUE, ADD_COVER_OPTION2_VALUE, BORE_CONTROLLER_OPTION2_VALUE, IP54_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
    'Z': new Set([BRAKE_KEY_OPTION2_VALUE, BORE_CONTROLLER_OPTION2_VALUE, BAYPASS_OPTION2_VALUE]),
};
