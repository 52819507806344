let config = {
    'API_URL': 'https://triol-api-dev.kube.raccoongang.com/',

    'REQUEST_RETRIES_TO_BACKEND': Infinity,
    'RETRY_DELAY_TO_BACKEND': (retryCount) => (retryCount > 4 ? 30 : Math.pow(2, retryCount)) * 1000, // 1, 2, 4, 8, 16, 30, 30, 30... seconds

    'DEFAULT_LANGUAGE': 'en',
    'LANGUAGES': {
        ru: 'Русский',
        en: 'English',
        ua: 'Українська',
    },

    'DESCRIPTION_INPUT_DELIMITER': {
        ru: {'.': ','},
        en: {',': '.'},
        ua: {'.': ','},
    },

    'PDF_LANGUAGE_MAP': {
        'ua': 'ru'
    }
};

export {config}
