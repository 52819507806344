<template>
    <div class="restart_modal-wrapper">
        <button class="restart_modal-btn" @click="showModal = true" type="button" aria-label="reset">
            {{ $t('general.restart') }}
        </button>
        <div v-if="showModal" class="restart" @close="showModal = false">
            <div class="restart_wrapper">
                <div class="restart_container">
                    <div class="restart_text">
                        <span>{{ $t('general.all_entered_data_will_be_discarded') }}.</span><br>
                        <span>{{ $t('general.proceed') }}?</span>
                    </div>
                    <div class="restart_button-wrapper">
                        <button class="restart_button" @click="showModal = false">
                            {{ $t('general.no') }}
                        </button>
                        <button class="restart_button" @click="reset">
                            {{ $t('general.yes') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Restart",
        data() {
            return{
                showModal: false,
            }
        },
        methods: {
            reset() {
                this.$store.dispatch('resetToStart');
                this.$router.push({name: 'startPage', params: {lang: this.$route.params.lang}});
            },
        }
    }
</script>

<style scoped>

</style>
