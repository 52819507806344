import VueI18n from "vue-i18n";
import Vue from "vue";
import convertMessagesToI18NFormat from "./utils";
import {config} from '../config';

Vue.use(VueI18n);

const messages = require('./messages.json');

export const i18n = new VueI18n({
    locale: config.DEFAULT_LANGUAGE,
    fallbackLocale: config.DEFAULT_LANGUAGE,
    silentTranslationWarn: true,
    messages: convertMessagesToI18NFormat(messages)
});
