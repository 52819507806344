/**
 * before:
 * {
 *     'general.phase_count': {
 *         ru: 'Количество фаз',
 *         en: 'Phase count'
 *     },
 *     'general.network_voltage': {
 *         ru: 'Номинальное напряжение сети',
 *         en: 'Rated Network voltage',
 *     }
 * }
 *
 * after:
 * {
 *     ru: {
 *         'general.phase_count': 'Количество фаз',
 *         'general.network_voltage': 'Номинальное напряжение сети',
 *     },
 *     en: {
 *         'general.phase_count': 'Phase count',
 *         'general.network_voltage': 'Rated Network voltage',
 *     }
 * }
 *
 */
export default function convertMessagesToI18NFormat(messages) {
    let i18nMessages = {};
    for (const [key, value] of Object.entries(messages)) {
        for (const [lang, text] of Object.entries(value)) {

            if (!Object.prototype.hasOwnProperty.call(i18nMessages, lang)) {
                i18nMessages[lang] = {};
            }
            i18nMessages[lang][key] = text;
        }
    }
    return i18nMessages;
}
