import {BACKEND_API} from "../axios-config";

export default {
    state: {
        // list of objects in the format [{value: String, text: String}, ...].
        // e.g. [{value: 'wall', text: 'Настенный'}, {value: 'floor', text: 'Напольный'}]
        placementsList: null,
        placement: null, // selected value ('value' key)

        // list of objects in the format [{id: Number, value: String}, ...].
        // e.g. [{id: 1, value: 'IP20'}, {id: 2, value: 'IP21'}, ...]
        degreesOfProtectionList: null,
        degreeOfProtection: null, // selected value ('id' key)

        // list of objects in the format [{value: String, text: String}, ...].
        // e.g. [{value: 'inside', text: 'Помещение'}, {value: 'outside', text: 'Улица'}]
        montageTypesList: null,
        montageType: null, // selected value ('value' key)
    },
    getters: {
        placementsList: state => state.placementsList,
        placement: state => state.placement,

        degreesOfProtectionList: state => state.degreesOfProtectionList,
        degreeOfProtection: state => state.degreeOfProtection,

        montageTypesList: state => state.montageTypesList,
        montageType: state => state.montageType,

        degreeOfProtectionValue: state => state.degreesOfProtectionList.find(degree => degree.id === state.degreeOfProtection).value,
    },
    mutations: {
        setPlacementsList(state, payload) {
            state.placementsList = payload;
        },
        setPlacement(state, payload) {
            state.placement = payload;
        },

        setDegreesOfProtectionList(state, payload) {
            state.degreesOfProtectionList = payload;
        },
        setDegreeOfProtection(state, payload) {
            state.degreeOfProtection = payload;
        },

        setMontageTypesList(state, payload) {
            state.montageTypesList = payload;
        },
        setMontageType(state, payload) {
            state.montageType = payload;
        },
    },
    actions: {
        setPlacement({commit}, value) {
            commit('setPlacement', value);
        },
        async loadPlacementsList({commit}) {
            const response = await BACKEND_API.get('placements/');
            commit('setPlacementsList', response.data);
        },

        setDegreeOfProtection({commit}, value) {
            commit('setDegreeOfProtection', value);
        },
        async loadDegreesOfProtectionList({commit}) {
            const response = await BACKEND_API.get('degrees_of_protection/');
            commit('setDegreesOfProtectionList', response.data);
        },

        setMontageType({commit}, value) {
            commit('setMontageType', value);
        },
        async loadMontageTypesList({commit}) {
            const response = await BACKEND_API.get('montage_types/');
            commit('setMontageTypesList', response.data);
        },
    }
}
