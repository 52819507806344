<template>
    <div class="error">
        <div class="error_wrapper">
            <div class="error_container">
                <span>{{ $t('general.server_not_responded') }}</span>
                <div id="circle"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Error"
    }
</script>

<style scoped>

</style>
