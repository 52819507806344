export default {
    name: "LocaleWatchMixin",
    data() {
        return {disabledUpdateData: false};
    },
    watch: {
        '$root.$i18n.locale': async function () {
            this.disabledUpdateData = true;
        }
    }
}
