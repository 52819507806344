<template>
    <div class="сomponent-wrapper">
        <triol-header/>
        <triol-link/>
        <form class="main">
            <section class="main_content">
                <h2 class="main_headline">
                    {{ $t('general.selecting_additional_options') }}<br>
                    {{ $t('general.remote_controls') }}
                </h2>
                <div class="main_content__flex">
                    <div class="main_content-controller">
                        <remote-control :class_="imageUrl"/>
                    </div>
                    <div class="main_content-controller">
                        <remote-control-radio-select :options="remoteControlsList" v-model="selectedRemoteControl" />
                        <div class="error_controllers"  v-if="boreControllerInSelectedOptions">
                            <p>{{ $t("options.option_selectedoption_selected") }}</p>
                            <p>{{ $t("options.installation_is_not_available") }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <div class="block-hidden"></div>
        </form>
        <progress-navigator @previous="previous" @next="next" :current-page="currentPage" />
    </div>
</template>

<script>
    import RemoteControl from "../RemoteControl";
    import TriolHeader from "../TriolHeader";
    import ProgressNavigator from "../ProgressNavigator";
    import {PAGE_FIFTH, PAGE_SEVENTH, PAGE_SIXTH} from "../../constants/page-constants";
    import RemoteControlRadioSelect from "../RemoteControlRadioSelect";
    import {BORE_CONTROLLER_OPTION2_ID} from "../../constants/option2-constants";
    import LocaleWatchMixin from "../../mixins/LocaleWatchMixin";
    import TriolLink from "../TriolLink";

    export default {
        name: "Page6",
        components: {RemoteControlRadioSelect, ProgressNavigator, TriolHeader, RemoteControl, TriolLink},
        mixins: [LocaleWatchMixin],
        data() {
            return {
                currentPage: PAGE_SIXTH,
            }
        },
        methods: {
            resetDataFromNextPages() {
                this.$store.dispatch('resetAfterPage6');
            },
            previous() {
                this.$router.push({name: `page${PAGE_FIFTH}`});
            },
            next() {
                if (this.$store.getters.pageNumber < PAGE_SIXTH) {
                    this.$store.dispatch('setPageNumber', PAGE_SIXTH);
                }
                this.$router.push({name: `page${PAGE_SEVENTH}`});
            },
        },
        computed: {
            boreControllerInSelectedOptions() {
                return this.$store.getters.selectedOptions2List.includes(BORE_CONTROLLER_OPTION2_ID);
            },
            remoteControlsList() {
                const noRemoteControlOption = {'id': null, 'name': 'general.without_remote_control', 'info_text': 'info_text.remotes.without_remote_control'};
                if (this.boreControllerInSelectedOptions) {
                    return [noRemoteControlOption];
                }

                let remotes = [...this.$store.getters.remoteControlsList];

                for (const remote of remotes) {
                    remote.prefix = 'general.remote_control';

                    // special case
                    remote.disabled = this.$store.getters.selectedOptions2List.includes(BORE_CONTROLLER_OPTION2_ID);
                }
                return [noRemoteControlOption, ...remotes];
            },
            selectedRemoteControl: {
                get() {
                    return this.$store.getters.selectedRemoteControl;
                },
                set(value) {
                    this.$store.dispatch('setRemoteControl', value);
                }
            },
            imageUrl() {
                return this.selectedRemoteControl ? "remote-control__" + this.$store.getters.remoteControlsList.find(rc => rc.id === this.selectedRemoteControl).name : "";
            }
        },
        created() {
            if (this.$store.getters.pageNumber < PAGE_FIFTH) {
                this.$router.push({name: `page${this.$store.getters.pageNumber + 1}`});
            }
        },
        beforeUpdate() {
            if (!this.disabledUpdateData) {
                this.resetDataFromNextPages();
            }
            this.disabledUpdateData = false;
        }
    }
</script>

<style scoped>

</style>
