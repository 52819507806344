<template>
    <div class="select" :class="selectClass">
        <select v-model="selected" :disabled="disabled">
            <option class="select_option" v-if="withEmpty" :value="null">{{ $t(emptyText) }}</option>

            <option class="select_dropdown" v-for="(option, index) in options" :value="option[optionKeyName]" :key="index">
                {{ $t(option[optionTextName]) }}
            </option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "CommonSelect",
        props: {
            value: null,
            withEmpty: {
                type: Boolean,
                default: false,
            },
            emptyText: {
                type: String,
                default: 'general.no_selected',
            },

            options: Array,
            optionKeyName: {
                type: String,
                default: 'value',
            },
            optionTextName: {
                type: String,
                default: 'text',
            },
            selectClass: String,

            disabled: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                selected: null,
            }
        },
        watch: {
            selected: function (newVal) {
                this.$emit('input', newVal)
            },
            value: {
                handler(newVal) {
                    this.selected = newVal;
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
