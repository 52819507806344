import {LAST_PAGE, PAGE_FIRST, PAGE_SECOND, PAGE_THIRD, PAGE_ZERO} from "../constants/page-constants";
import {
    FLOW_MAX_VALUE_BY_LINE_MAP,
    FLOW_MIN_VALUE_BY_LINE_MAP,
} from "../constants/power-constants";
import {VOLTAGE_VALUE_MAP} from "../constants/voltage-constants";

export default {
    name: "FilteredFCListMixin",
    computed: {
        fullFCList: {
            get() {
                return this.$store.getters.fullFCList;
            }
        },
        fcAndOption1List: {
            get() {
                return this.$store.getters.fcAndOption1List;
            }
        },
        option1List: {
            get() {
                return this.$store.getters.option1List;
            }
        },
        fcAndOption2List: {
            get() {
                return this.$store.getters.fcAndOption2List;
            }
        },
        option2List: {
            get() {
                return this.$store.getters.option2List;
            }
        },
        page: {
            get() {
                return this.$store.getters.pageNumber;
            }
        },
    },
    methods: {
        checkUniqueValueByFieldName(fieldName, page = LAST_PAGE) {
            let fcList = this.getFCList(page);

            return fcList && fcList.length && fcList.every(fc => fc[fieldName] === fcList[0][fieldName]) ? fcList[0][fieldName] : null;
        },
        getUniqueValuesByFieldName(fcList, fieldName) {
            return fcList ? fcList.map(fc => fc[fieldName]).filter((v, i, a) => a.indexOf(v) === i) : null;
        },
        getUniqueValuesByFieldNameIfList(fcList, fieldName) {
            return fcList ? fcList.map(fc => fc[fieldName]).flat().filter((v, i, a) => a.indexOf(v) === i) : null;
        },

        simpleFilterListByFieldName(list, fieldName, value) {
            if (['', null].includes(value)) {
                return list;
            }
            return list.filter(fc => fc[fieldName] === value);
        },
        filterListByFieldNameIfList(list, fieldName, value) {
            if (['', null].includes(value)) {
                return list;
            }
            return list.filter(fc => fc[fieldName].includes(value));
        },

        lessOrEqualFilterListByFieldName(list, fieldName, value) {
            if (['', null].includes(value)) {
                return list;
            }
            return list.filter(fc => fc[fieldName] <= value);
        },
        greatOrEqualFilterListByFieldName(list, fieldName, value) {
            if (['', null].includes(value)) {
                return list;
            }
            return list.filter(fc => fc[fieldName] >= value);
        },

        getFCList(page = null) {
            if (!page && page !== PAGE_ZERO) {
                page = this.page;
            }

            let fcList = this.fullFCList;
            if (!fcList) {
                return null;
            }

            if (page >= PAGE_FIRST) {
                fcList = this.filterListByFieldNameIfList(fcList, 'voltages_ids', this.$store.getters.voltage);

                if (this.$store.getters.engineType) {
                    fcList = this.simpleFilterListByFieldName(fcList, 'is_sync_engine_type', this.$store.getters.engineType);
                }
                if (this.$store.getters.flow) {
                    const voltageValue = VOLTAGE_VALUE_MAP[this.$store.getters.voltageValue];
                    for (const [line, constraint] of Object.entries(FLOW_MIN_VALUE_BY_LINE_MAP)) {
                        if (Object.prototype.hasOwnProperty.call(constraint, voltageValue)
                            && this.$store.getters.flow < constraint[voltageValue]) {
                            fcList = fcList.filter(fc => fc.line !== line);
                        }
                    }
                    for (const [line, constraint] of Object.entries(FLOW_MAX_VALUE_BY_LINE_MAP)) {
                        if (Object.prototype.hasOwnProperty.call(constraint, voltageValue)
                            && this.$store.getters.flow > constraint[voltageValue]) {
                            fcList = fcList.filter(fc => fc.line !== line);
                        }
                    }
                }
            }
            if (page >= PAGE_SECOND) {
                fcList = this.simpleFilterListByFieldName(fcList, 'power', this.$store.getters.humanizedPower);
                fcList = this.simpleFilterListByFieldName(fcList, 'implementation', this.$store.getters.implementation);

                fcList = this.lessOrEqualFilterListByFieldName(fcList,
                    'lower_bound_temp', this.$store.getters.lowerBoundTemp);
                fcList = this.greatOrEqualFilterListByFieldName(fcList,
                    'max_temp', this.$store.getters.upperBoundTemp);

                if (this.$store.getters.conductiveDust) {
                    fcList = this.simpleFilterListByFieldName(fcList, 'conductive_dust', this.$store.getters.conductiveDust);
                }
            }

            if (page >= PAGE_THIRD) {
                fcList = this.simpleFilterListByFieldName(fcList, 'placement', this.$store.getters.placement);
                fcList = this.filterListByFieldNameIfList(fcList, 'degrees_of_protection_ids', this.$store.getters.degreeOfProtection);
                fcList = this.simpleFilterListByFieldName(fcList, 'montage_type', this.$store.getters.montageType);
            }
            return fcList;
        },
        getFC() {
            let fcList = this.getFCList();

            if (fcList && fcList.length === 1) {
                return fcList[0];
            }
            return null;
        },

        getOption1List() {
            let fcAndO1List = this.getFcAndO1ListByFCAndVoltage();
            let fcAndO1Ids = this.getUniqueValuesByFieldName(fcAndO1List, 'option_id');

            let option1List = this.option1List;
            return fcAndO1List && fcAndO1Ids && option1List
                ? option1List.filter(o1 => fcAndO1Ids.includes(o1.id))
                : null;
        },

        getFcAndO1ListByFCAndVoltage() {
            let fcAndO1List = this.fcAndOption1List;
            let fc = this.getFC();
            let voltageId = this.$store.getters.voltage;

            return fcAndO1List && fc && voltageId
                ? fcAndO1List.filter(o1 => o1.voltage_id === voltageId && fc.id === o1.frequency_converter_id)
                : null;
        },

        getOption2List() {
            let fcAndO2List = this.getFcAndO2ListByFC();
            let fcAndO2Ids = this.getUniqueValuesByFieldName(fcAndO2List, 'option_id');

            let option2List = this.option2List;
            return fcAndO2List && fcAndO2Ids && option2List
                ? option2List.filter(o2 => fcAndO2Ids.includes(o2.id))
                : null;
        },
        getFcAndO2ListByFC() {
            let fcAndO2List = this.fcAndOption2List;
            let fc = this.getFC();

            return fcAndO2List && fc ? fcAndO2List.filter(o2 => fc.id === o2.frequency_converter_id) : null;
        },

        getNomenclature() {
            return this.checkUniqueValueByFieldName('nomenclature') || '____';
        },
        getVoltageChar() {
            return this.checkUniqueValueByFieldName('voltage') || '_';
        },
        getLine() {
            return this.checkUniqueValueByFieldName('line') || '__';
        },
    }
}
