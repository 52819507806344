import {BACKEND_API} from "../axios-config";

export default {
    state: {
        remoteControlsList: null,
        selectedRemoteControl: null // selected 'id' value
    },
    getters: {
        remoteControlsList: state => state.remoteControlsList,
        selectedRemoteControl: state => state.selectedRemoteControl,

        remoteControlObject: state => state.remoteControlsList.find(rc => rc.id === state.selectedRemoteControl),
    },
    mutations: {
        setRemoteControlsList(state, payload) {
            state.remoteControlsList = payload;
        },

        setRemoteControl(state, payload) {
            state.selectedRemoteControl = payload;
        },
    },
    actions: {
        async loadRemoteControlsList({commit}) {
            const response = await BACKEND_API.get('remote_controls/');
            commit('setRemoteControlsList', response.data);
        },
        setRemoteControl({commit}, value) {
            commit('setRemoteControl', value);
        }
    }
}
