import {
    NO_PAGE,
    PAGE_FIFTH,
    PAGE_FIRST,
    PAGE_FOURTH,
    PAGE_SECOND,
    PAGE_THIRD,
    PAGE_ZERO
} from "../constants/page-constants";
import {
    DEFAULT_ALTITUDE_VALUE,
    DEFAULT_LOWER_BOUND_TEMP,
    DEFAULT_UPPER_BOUND_TEMP
} from "../constants/operation-constants";
import {DEFAULT_ENGINE_TYPE_VALUE} from "../constants/power-constants";
import {BORE_CONTROLLER_OPTION2_ID} from "../constants/option2-constants";

export default {
    actions: {
        resetToStart({dispatch, rootGetters}, options) {
            if (rootGetters.pageNumber === NO_PAGE) {
                return;
            }

            dispatch('setVoltage', null);
            dispatch('setEngineType', DEFAULT_ENGINE_TYPE_VALUE);

            dispatch('setUserFlow', '');
            dispatch('setUserPower', '');
            dispatch('setUserPowerMeasure', 1);

            dispatch('setFlow', null);
            dispatch('setPower', null);
            dispatch('setEfficiency', '');
            dispatch('setCos', '');

            dispatch('resetAfterPage1', options);
            dispatch('setPageNumber', NO_PAGE);
        },
        resetAfterPage1({dispatch, rootGetters}, options) {
            if (rootGetters.pageNumber <= PAGE_ZERO) {
                return;
            }

            dispatch('setImplementation', null);
            dispatch('setTool', null);
            dispatch('setToolMode', null);
            dispatch('setModeOfOps', null);

            dispatch('setConductiveDust', null);
            dispatch('setAltitudeMeasure', 1);
            dispatch('setTempMeasure', 1);
            dispatch('setAltitude', '');
            dispatch('setUpperBoundTemp', null);
            dispatch('setLowerBoundTemp', null);
            dispatch('setUserAltitude', DEFAULT_ALTITUDE_VALUE);
            dispatch('setUserUpperBoundTemp', DEFAULT_UPPER_BOUND_TEMP);
            dispatch('setUserLowerBoundTemp', DEFAULT_LOWER_BOUND_TEMP);
            dispatch('setHumanizedPower', null);

            dispatch('resetAfterPage2', options);

            dispatch('setPageNumber', PAGE_ZERO);
        },
        resetAfterPage2({dispatch, rootGetters}, options) {
            if (rootGetters.pageNumber <= PAGE_FIRST) {
                return;
            }

            dispatch('setHumanizedPower', null);
            dispatch('setPlacement', null);
            dispatch('setDegreeOfProtection', null);
            dispatch('setMontageType', null);

            dispatch('resetAfterPage3', options);

            dispatch('setPageNumber', PAGE_FIRST);
        },
        resetAfterPage3({dispatch, rootGetters}, options) {
            if (rootGetters.pageNumber <= PAGE_SECOND) {
                return;
            }

            dispatch('clearSelectedOption1Set');
            dispatch('setOption1Display', false);

            let selectedBoreController = null;
            if (options && options.savePage6) {
                selectedBoreController = rootGetters.selectedOptions2List.includes(BORE_CONTROLLER_OPTION2_ID);
            }
            dispatch('clearSelectedOption2Set');
            if (selectedBoreController) {
                dispatch('selectOption2', BORE_CONTROLLER_OPTION2_ID)
            }
            dispatch('setOption2Display', false);

            dispatch('resetAfterPage4', options);

            dispatch('setPageNumber', PAGE_SECOND);
        },
        resetAfterPage4({dispatch, rootGetters}, options) {
            if (rootGetters.pageNumber <= PAGE_THIRD) {
                return;
            }

            if (!options || !options.savePage5) {
                dispatch('setSelectedAIO2Count', 0);
                dispatch('setSelectedExt24DinCount', 0);
                dispatch('setSelectedEncoder', null);
                dispatch('setSelectedInterface', null);
            }

            dispatch('resetAfterPage5', options);

            dispatch('setPageNumber', PAGE_THIRD);
        },
        resetAfterPage5({dispatch, rootGetters}, options) {
            if (rootGetters.pageNumber <= PAGE_FOURTH) {
                return;
            }
            if (!options || !options.savePage6) {
                dispatch('setRemoteControl', null);
            }
            dispatch('resetAfterPage6');

            dispatch('setPageNumber', PAGE_FOURTH);
        },
        resetAfterPage6({dispatch, rootGetters}) {
            if (rootGetters.pageNumber <= PAGE_FIFTH) {
                return;
            }
            dispatch('setPageNumber', PAGE_FIFTH);
        },
    }
}
