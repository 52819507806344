import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import PowerStore from './power-store';
import VoltageStore from './voltage-store';
import ImplementationStore from './implementation-store';
import OperationConditionsStore from './operation-conditions';
import ConditionsStore from './conditions-store';
import ResetActionsStore from './reset-actions-store';
import Option1Store from './option1-store';
import Option2Store from './option2-store';
import ExpandBlocksStore from './expand-blocks-store';
import RemoteControl from './remote-controls-store';
import {BACKEND_API} from "../axios-config";
import {NO_PAGE} from "../constants/page-constants";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        fullFCList: null,

        pageNumber: NO_PAGE,

        humanizedPower: null,

        showGlobalError: false,

        infoBoxActive: null,
    },
    getters: {
        fullFCList: state => state.fullFCList,
        pageNumber: state => state.pageNumber,

        humanizedPower: state => state.humanizedPower,

        showGlobalError: state => state.showGlobalError,
        infoBoxActive: state => state.infoBoxActive,
    },
    mutations: {
        setHumanizedPower(state, payload) {
            state.humanizedPower = payload;
        },
        setPageNumber(state, payload) {
            state.pageNumber = payload;
        },

        setFullFCList(state, payload) {
            state.fullFCList = payload;
        },
        setShowGlobalError(state, payload) {
            state.showGlobalError = payload;
        },
        setInfoBoxActive(state, payload) {
            state.infoBoxActive = payload;
        }
    },
    actions: {
        async loadFullFCList({commit}) {
            let response = await BACKEND_API.get('fc/');
            commit('setFullFCList', response.data);
        },
        setPageNumber({commit}, value) {
            commit('setPageNumber', value)
        },
        setInfoBoxActive({commit}, value) {
            commit('setInfoBoxActive', value)
        },
        setHumanizedPower({commit}, value) {
            commit('setHumanizedPower', value)
        },
        async loadHumanizedPower(
            {dispatch, rootGetters},
            {lowerBoundTemp, upperBoundTemp}
        ) {
            const params = new URLSearchParams({
                mode_of_ops: rootGetters.modeOfOps || rootGetters.toolMode,
                voltage: rootGetters.voltageValue,

                user_lower_bound_temp: lowerBoundTemp,
                user_upper_bound_temp: upperBoundTemp,
                user_altitude: rootGetters.altitude,
            });
            if (rootGetters.flow) {
                params.set('flow', rootGetters.flow);
            } else {
                if (rootGetters.efficiency) {
                    params.set('efficiency', rootGetters.efficiency);
                }
                if (rootGetters.cos) {
                    params.set('cos', rootGetters.cos);
                }
                if (rootGetters.power) {
                    params.set('power', rootGetters.power);
                }
            }
            if (rootGetters.conductiveDust) {
                params.set('conductive_dust', rootGetters.conductiveDust);
            }
            if (rootGetters.implementation) {
                params.set('implementation', rootGetters.implementation)
            }

            let response = await BACKEND_API.get('fc/power_label/', {params: params});
            if (response.data) {
                dispatch('setHumanizedPower', response.data.power);
            } else {
                dispatch('setHumanizedPower', null);
            }
        },
        setShowGlobalError({commit}, value) {
            commit('setShowGlobalError', value);
        }
    },
    modules: {
        power: PowerStore,
        voltage: VoltageStore,
        implementation: ImplementationStore,
        operationConditions: OperationConditionsStore,
        conditions: ConditionsStore,
        resetActions: ResetActionsStore,
        option1: Option1Store,
        option2: Option2Store,
        expandBlocks: ExpandBlocksStore,
        remoteControls: RemoteControl,
    },
    plugins: [createPersistedState({storage: window.sessionStorage})],
});
