<template>
    <div class="checkbox_wrapper">
        <div class="checkbox_box" :class="[boxClass, engineTypeClass]" v-for="(option, index) in options" :key="index">
            <label class="checkbox" :class="[checkboxClass,
                                             {'checkbox__disabled' : disabled || option.disabled
                                                                     || disabledSelected && selected === option[optionKeyName]},
                                             {'checkbox__active': selected === option[optionKeyName]}]">
                <input class="checkbox_radio" type="radio" :value="option[optionKeyName]" v-model="selected"
                       :id="'select-' + uniqueElementKey + '-' + index"
                       :disabled="disabled || option.disabled || disabledSelected && selected === option[optionKeyName]"
                       @click="uncheckIfAlreadySelected">
                <label class="checkbox_label" :for="'select-' + uniqueElementKey + '-' + index">
                    {{ $t(option[optionTextName]) }}
                </label>
            </label>
            <info-box v-if="showInfoButton" :class="infoBoxClass" :text-key="option['info_text']" :index="'select-' + uniqueElementKey + '-' + index"/>
        </div>
    </div>
</template>

<script>
    import InfoBox from "./InfoBox"


    export default {
        name: "RadioSelect",
        components: {InfoBox},
        props: {
            value: null,

            options: Array,
            optionKeyName: {
                type: String,
                default: 'value',
            },
            optionTextName: {
                type: String,
                default: 'text',
            },
            checkboxClass: String,
            boxClass: String,
            engineTypeClass: String,
            infoBoxClass: String,

            uniqueElementKey: {
                type: String,
                default: '',
            },
            showInfoButton: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            disabledSelected: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                selected: null,
            }
        },
        methods: {
            uncheckIfAlreadySelected(event) {
                if (event.target.value === this.selected
                        || typeof this.selected === 'number' && parseInt(event.target.value) === this.selected
                        || typeof this.selected === 'boolean' && event.target.value === "true" && this.selected
                ) {
                    this.selected = null;
                }
            }
        },
        watch: {
            selected: function (newVal) {
                this.$emit('input', newVal)
            },
            value: {
                handler(newVal) {
                    this.selected = newVal
                },
                immediate: true,
            }
        }
    }
</script>

<style scoped>

</style>
