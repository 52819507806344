<template>
    <div class="select_min">
        <select v-model="selectedCount">
            <option :value="0">0</option>
            <option v-for="index in count" :value="index" :key="index">
                {{index}}
            </option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "CounterSelect",
        props: {
            value: {
                type: Number,
                default: 0
            },
            count: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                selected: null,
            }
        },
        computed: {
            selectedCount: {
                get() {
                    return this.selected;
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
        },
        watch: {
            value: {
                handler(value) {
                    this.selected = value;
                },
                immediate: true,
            }
        }
    }
</script>

<style scoped>

</style>
