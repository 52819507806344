export const IMPLEMENTATION_GENERAL = 'general';
export const IMPLEMENTATION_PETROL = 'petrol';
export const IMPLEMENTATION_LIFT = 'lift';

export const UC_LINE = 'UC';
export const UH_LINE = 'UH';
export const SD_LINE = 'SD';
export const SR_LINE = 'SR';
export const RT_LINE = 'RT';
export const LE_LINE = 'LE';

export const IMAGE_LANG = {
    ru: require('@/assets/image/StartLogo/logoRU.svg'),
    en: require('@/assets/image/StartLogo/logoEN.svg'),
    ua: require('@/assets/image/StartLogo/logoUA.svg'),
};
