<template>
    <div class="сomponent-wrapper">
        <triol-header/>
        <triol-link/>
        <form class="main">
            <h2 class="main_headline">{{ $t('general.operating_conditions') }}</h2>
            <section class="main_content main_content__flex">
                <div class="width-half-50">
                    <div class="select_component-wrapper">
                        <div class="select_label">{{ $t('general.placement') }}</div>
                        <common-select v-model="placement" :options="placementsList" :class="{ 'select__error': $v.placement.$error}"
                                       :with-empty="placementsList.length > 1" :disabled="placementsList.length === 1" />
                    </div>
                    <div class="select_component-wrapper" v-if="placement">
                        <div class="select_label">{{ $t('general.degree_of_protection') }}</div>
                        <common-select v-model="degreeOfProtection" :options="degreesOfProtectionList" :class="{ 'select__error': $v.degreeOfProtection.$error}"
                                    :with-empty="degreesOfProtectionList.length > 1" option-text-name="value" option-key-name="id"
                                    :disabled="degreesOfProtectionList.length === 1"/>
                    </div>
                    <div class="select_component-wrapper" v-if="degreeOfProtection">
                        <div class="select_label">{{ $t('general.montage_type') }}</div>
                        <common-select v-model="montageType" :options="montageTypesList" :class="{ 'select__error': $v.montageType.$error}"
                                    :with-empty="montageTypesList.length > 1" :disabled="montageTypesList.length === 1"/>
                    </div>
                </div>
                <div class="width-half-50 equipment__center-j">
                    <div :class="currentImageURL"></div>
                </div>
            </section>
            <nomenclature/>
            <div class="block-hidden"></div>
        </form>
        <progress-navigator @previous="previous" @next="submit" :current-page="currentPage"/>
    </div>
</template>

<script>
    import ProgressNavigator from "../ProgressNavigator";
    import Nomenclature from "../Nomenclature";
    import CommonSelect from "../CommonSelect";
    import {required} from "vuelidate/lib/validators";
    import {PAGE_FOURTH, PAGE_SECOND, PAGE_THIRD} from "../../constants/page-constants";
    import TriolHeader from "../TriolHeader";
    import {UC_LINE} from "../../constants/constants";
    import {powerFromLabelToNumber} from "../../utils/utils";
    import LocaleWatchMixin from "../../mixins/LocaleWatchMixin";
    import ErrorScrollMixin from "../../mixins/ErrorScrollMixin";
    import TriolLink from "../TriolLink";

    export default {
        name: "Page3",
        components: {TriolHeader, CommonSelect, Nomenclature, ProgressNavigator, TriolLink},
        mixins: [LocaleWatchMixin, ErrorScrollMixin],
        data() {
            return {
                currentPage: PAGE_THIRD,
                placementLoaded: false,
                degreeOfProtectionLoaded: false,
                imagesURLs: {
                    UC7K5M40: 'equipment_UC7K5M40',
                    UH: 'equipment_UH',
                    SD: 'equipment_SD',
                    UCK375K5: 'equipment_UCK375K5',
                    SR: 'equipment_SR',
                    RT: 'equipment_RT',
                    LE: 'equipment_LE',
                }
            }
        },
        created() {
            if (this.$store.getters.pageNumber < PAGE_SECOND) {
                this.$router.push({name: `page${this.$store.getters.pageNumber + 1}`});
            }

            this.refreshPlacement();
            this.refreshDegreeOfProtection();
            this.refreshMontageType();
        },
        validations() {
            return {
                placement: {required},
                degreeOfProtection: {required},
                montageType: {required},
            };
        },
        methods: {
            resetDataFromNextPages(savePage5 = false, savePage6 = false) {
                this.$store.dispatch('resetAfterPage3', {savePage5: savePage5, savePage6: savePage6});
            },

            async submit() {
                if (this.$v.$invalid) {
                    // TODO: display errors
                    await this.$v.$touch();
                    this.errorScrollFC()
                    return;
                }

                if (this.$store.getters.pageNumber < PAGE_THIRD) {
                    this.$store.dispatch('setPageNumber', PAGE_THIRD);
                }
                this.$router.push({name: `page${PAGE_FOURTH}`});
            },
            previous() {
                this.$router.push({name: `page${PAGE_SECOND}`});
            },

            preparePlacementsList(placementsList) {
                if (!placementsList) {
                    return null;
                }

                let uniqueValues = this.getUniqueValuesByFieldName(this.getFCList(PAGE_SECOND), 'placement');
                return placementsList.filter(placement => uniqueValues.includes(placement.value));
            },
            prepareDegreesOfProtectionList(degreesOfProtectionList) {
                if (!degreesOfProtectionList) {
                    return null;
                }

                let fcList = this.simpleFilterListByFieldName(this.getFCList(PAGE_SECOND), 'placement', this.placement);
                let uniqueValues = this.getUniqueValuesByFieldNameIfList(fcList, 'degrees_of_protection_ids');

                return degreesOfProtectionList.filter(degree => uniqueValues.includes(degree.id));
            },
            prepareMontageTypesList(montageTypesList) {
                if (!montageTypesList) {
                    return null;
                }
                let fcList = this.simpleFilterListByFieldName(this.getFCList(PAGE_SECOND), 'placement', this.placement);
                fcList = this.filterListByFieldNameIfList(fcList, 'degrees_of_protection_ids', this.degreeOfProtection);

                let uniqueValues = this.getUniqueValuesByFieldName(fcList, 'montage_type');
                return montageTypesList.filter(type => uniqueValues.includes(type.value));
            },

            refreshPlacement() {
                if (!this.placement) {
                    this.placement = this.placementsList.length === 1 ? this.placementsList[0].value : null;
                }
            },
            refreshDegreeOfProtection() {
                if (!this.degreeOfProtection) {
                    this.degreeOfProtection = this.degreesOfProtectionList.length === 1 ? this.degreesOfProtectionList[0].id : null;
                }
            },
            refreshMontageType() {
                if (!this.montageType) {
                    this.montageType = this.montageTypesList.length === 1 ? this.montageTypesList[0].value : null;
                }
            }
        },
        computed: {
            currentImageURL() {
                let line = this.checkUniqueValueByFieldName('line');
                if (!line)
                    return;

                let key = line;
                if (line === UC_LINE) {
                    key = powerFromLabelToNumber(this.$store.getters.humanizedPower) > 5.5 ? 'UC7K5M40' : 'UCK375K5';
                }
                return this.imagesURLs[key];
            },

            placementsList: {
                get() {
                    return this.preparePlacementsList(this.$store.getters.placementsList);
                }
            },
            placement: {
                get() {
                    return this.$store.getters.placement;
                },
                set(value) {
                    this.$store.dispatch('setPlacement', value);

                    if (this.placementLoaded) {
                        this.degreeOfProtection = null;
                        this.refreshDegreeOfProtection();
                    }
                    this.placementLoaded = true;
                }
            },

            degreesOfProtectionList: {
                get() {
                    return this.prepareDegreesOfProtectionList(this.$store.getters.degreesOfProtectionList);
                }
            },
            degreeOfProtection: {
                get() {
                    return this.$store.getters.degreeOfProtection;
                },
                set(value) {
                    this.$store.dispatch('setDegreeOfProtection', value);

                    if (this.degreeOfProtectionLoaded) {
                        this.montageType = null;
                        this.refreshMontageType();
                    }
                    this.degreeOfProtectionLoaded = true;
                }
            },

            montageTypesList: {
                get() {
                    return this.prepareMontageTypesList(this.$store.getters.montageTypesList);
                }
            },
            montageType: {
                get() {
                    return this.$store.getters.montageType;
                },
                set(value) {
                    this.$store.dispatch('setMontageType', value);
                }
            },
        },
        beforeUpdate() {
            if (!this.disabledUpdateData) {
                this.resetDataFromNextPages(true, true);
            }
            this.disabledUpdateData = false;
        }
    }
</script>

<style scoped>

</style>
