<template>
    <div>
        <button class="info-box" type="button" aria-label="info button" :class="{ 'info-box_active': showInfo }" @click="setShowInfo">
            <img :src="imageInfo" alt="Info" width="20px" height="20px">
            <div class="info-box_wrapper">
                <div class="info-box_text">
                    <span class="info-box_label">
                        <img :src="imageInfoBlue" alt="Info">
                        {{ $t('info.information') }}
                    </span>
                    <span v-html="$t(textKey).replaceAll('\n', '<br>')"/>
                </div>
            </div>
        </button>
    </div>
</template>

<script>
    export default {
        name: "InfoBox",
        props: {
            textKey: {
                required: true,
                type: String,
            },
            index: {
                required: true,
                type: String,
            }
        },
        data() {
            return {
                imageInfo: require('@/assets/image/help_outline_24px.svg'),
                imageInfoBlue: require('@/assets/image/help_outline_blue24px.svg'),
                showInfo: false,
            }
        },
        methods: {
            setShowInfo() {
                this.showInfo = !this.showInfo;
                this.$store.dispatch("setInfoBoxActive", this.showInfo ? this.index : null);
            }
        },
        watch: {
            "$store.getters.infoBoxActive": function(newValue) {
                if (newValue != this.index) {
                    this.showInfo = false;
                }
            }
        }
    }
    
</script>

<style scoped>

</style>
