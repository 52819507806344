import {BACKEND_API} from "../axios-config";

export default {
    state: {
        option1List: null,
        fcAndOption1List: null,

        selectedOptions1List: [], // set of values of 'id' field
        option1Display: false,
    },
    getters: {
        option1List: state => state.option1List,
        fcAndOption1List: state => state.fcAndOption1List,

        selectedOptions1List: state => state.selectedOptions1List,
        option1Display: state => state.option1Display,

        selectedOptions1Objects: state => state.option1List.filter(option => state.selectedOptions1List.includes(option.id)),
    },
    mutations: {
        setOption1List(state, payload) {
            state.option1List = payload;
        },
        setFCAndOption1List(state, payload) {
            state.fcAndOption1List = payload;
        },

        selectOption1(state, payload) {
            if (!state.selectedOptions1List.includes(payload)) {
                state.selectedOptions1List.push(payload);
                state.selectedOptions1List = [...state.selectedOptions1List]; // for reactivity
            }
        },
        unselectOption1(state, payload) {
            const index = state.selectedOptions1List.indexOf(payload);
            if (index > -1) {
                state.selectedOptions1List.splice(index, 1);
                state.selectedOptions1List = [...state.selectedOptions1List]; // for reactivity
            }
        },
        clearSelectedOption1Set(state) {
            state.selectedOptions1List = [];
        },

        setOption1Display(state, payload) {
            state.option1Display = payload;
        },
    },
    actions: {
        async loadOption1List({commit}) {
            const response = await BACKEND_API.get('option1_list/');
            commit('setOption1List', response.data);
        },

        async loadFCAndOption1List({commit}) {
            const response = await BACKEND_API.get('fc/fc_and_o1/');
            commit('setFCAndOption1List', response.data);
        },
        selectOption1({commit}, value) {
            commit('selectOption1', value);
        },
        unselectOption1({commit}, value) {
            commit('unselectOption1', value);
        },

        setOption1Display({commit}, value) {
            commit('setOption1Display', value);
        },
        clearSelectedOption1Set({commit}) {
            commit('clearSelectedOption1Set')
        }
    }
}
