import axios from "axios";
import {config} from './config';
import axiosRetry from "axios-retry";
import {store} from "./store/store";

export const BACKEND_API = axios.create({baseURL: config['API_URL'] + 'api/'});

BACKEND_API.interceptors.response.use(
    response => {
        store.dispatch('setShowGlobalError', false);
        return response;
    },
    error => {
        if (!error.response) {
            store.dispatch('setShowGlobalError', true);
        }
        return Promise.reject(error)
    }
);

axiosRetry(BACKEND_API, {
    retries: config.REQUEST_RETRIES_TO_BACKEND,
    retryDelay: config.RETRY_DELAY_TO_BACKEND,
});
