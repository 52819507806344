class PowerManager {
    constructor(flow, power, efficiency, cos, voltage) {
        this.flow = this.prepareValue(flow);
        this.power = this.prepareValue(power);
        this.efficiency = this.prepareValue(efficiency);
        this.cos = this.prepareValue(cos);
        this.voltage = voltage;
    }

    prepareValue(value) {
        if (typeof value === 'number')
            return value;

        if (!value)
            return null;

        return parseFloat(value);
    }

    getFullInfo() {
        if (this.flow === null && this.power === null) {
            throw Error('Required either flow or power');
        }

        if (this.flow !== null)
            return [this.flow, this.power, this.efficiency, this.cos];

        if (this.efficiency !== null && this.cos !== null) {
            let flow = this.calculateFlow(this.power, this.efficiency, this.cos, this.voltage);
            return [flow, this.power, this.efficiency, this.cos];
        }

        return [this.flow, this.power, this.efficiency, this.cos];
    }

    calculateFlow(power, efficiency, cos, voltage) {
        // efficiency is expressed as a percentage, so it's multiplied by 100
        // power is expressed in kilowatt, so it's multiplied by 1000
        return power / voltage / efficiency / cos / Math.sqrt(3) * 100 * 1000;
    }
    calculatePower() {
        return this.flow * this.voltage * this.efficiency * this.cos * Math.sqrt(3) / 100 / 1000;
    }
}

export default PowerManager
