import { config } from '../config';

export default {
    name: "DescriptionInputMixin",
    methods: {
        convertDescription(value) {
            for (const [from, to] of Object.entries(config.DESCRIPTION_INPUT_DELIMITER[this.$route.params.lang])) {
                value = value.replaceAll(from, to);
            }
            value = value[0] + value.substring(1).replace('-', '...');
            return value;
        },
    }
}
