<template>
    <header class="header">
        <select class="header_translation" v-model="$root.$i18n.locale">
            <option v-for="(lang, key) in languages" :key="key" :value="key">
                {{ lang }}
            </option>
        </select>
    </header>
</template>

<script>
    import {config} from "../config";
    export default {
        name: "TriolHeader",
        data() {
            return {
                languages: config.LANGUAGES
            }
        },
        watch: {
            '$root.$i18n.locale': async function (lang) {
                let newUrl = {
                    name: this.$route.name,
                    params: {lang: lang},
                };
                await this.$router.push(newUrl);
            }
        }
    }
</script>

<style scoped>

</style>
